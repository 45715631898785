import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants/clientFeatures';
import {
 IAffiliateLinksFormValues, IShopifyPromoCodeFormValues, PROMO_OFFER_TYPES, TFormValues,
} from '../types';

export const getRequiredFields = (
  formValues: TFormValues,
  formType: OFFER_SOURCE,
  isAdvanceUrlEnabled: boolean = false,
  migrateToGraphQL: boolean = false,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const linkShopifyOffer = useClientFeatureEnabled(ClientFeature.LINK_SHOPIFY_OFFER);
  const requiredFieldConfiguration = {
    requiredFields: ['name', 'description'],
    requiredFieldsErrorText: {
      name: 'Please add an Offer Name',
      description: 'Please add an Offer Description',
    },
  };
  if (formType === OFFER_SOURCE.TUNE) {
    const tuneRequiredFields = ['conversionType', 'conversionTrackingType', 'url', 'expirationDate', 'status'];
    const advanceUrlRequiredField = ['utmRequired'];
    if (!migrateToGraphQL) tuneRequiredFields.push('payoutType');
    requiredFieldConfiguration.requiredFields = requiredFieldConfiguration.requiredFields.concat(tuneRequiredFields);
    if (isAdvanceUrlEnabled) {
      const utmFormValues = formValues as IAffiliateLinksFormValues;
      if (
        !utmFormValues.utmSource
        && !utmFormValues.utmMedium
        && !utmFormValues.utmCampaign
        && !utmFormValues.utmContent
        && !utmFormValues.utmTerm
      ) {
        requiredFieldConfiguration.requiredFields = requiredFieldConfiguration.requiredFields.concat(
          advanceUrlRequiredField,
        );
      }
    }

    const tuneRequiredFieldsErrorText = {
      conversionType: 'Please select a Conversion Type',
      payoutType: 'Please select a Payout Option',
      conversionTrackingType: 'Please select a Conversion Tracking type',
      url: 'Please add an Offer URL',
      expirationDate: 'Please select an Expiration Date',
      status: 'Please select a Status',
      utmRequired: 'Please add one UTM Parameter',
      payoutOptions: 'Please add payout options details',
    };
    if (migrateToGraphQL) {
      requiredFieldConfiguration.requiredFields.push('payoutOptions');
      delete tuneRequiredFieldsErrorText.payoutType;
    }
    requiredFieldConfiguration.requiredFieldsErrorText = {
      ...requiredFieldConfiguration.requiredFieldsErrorText,
      ...tuneRequiredFieldsErrorText,
    };
  }
  if (formType === OFFER_SOURCE.SHOPIFY) {
    const shopifyRequireFields = ['prefixType'];
    if (!linkShopifyOffer) {
      shopifyRequireFields.push('priceRuleType', 'priceRuleAmount');
    }
    const newVAlues = formValues as IShopifyPromoCodeFormValues;
    if (newVAlues.offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER) {
      shopifyRequireFields.push('linkedShopifyOfferId');
    }
    if (newVAlues.isMultipleShopifySyncEnabled && newVAlues.offerType === PROMO_OFFER_TYPES.LINKED_SHOPIFY_OFFER) {
      const selectedStores = newVAlues.clientsForSync || [];
      selectedStores.forEach((store) => {
        if (store.isSelected && (store.discountCodeGid === null || store.discountCodeGid === undefined)) {
          shopifyRequireFields.push('discountCodeGid');
        }
        if (newVAlues.linkedShopifyOfferId === store.discountCodeGid) {
          shopifyRequireFields.push('discountCodeGidIncorrect');
        }
      });
    }
    if (newVAlues.isAdvancedUrlEnabled && !newVAlues.isSecureCodes) {
      const utmFormValues = formValues as IAffiliateLinksFormValues;
      if (
        !utmFormValues.utmSource
        && !utmFormValues.utmMedium
        && !utmFormValues.utmCampaign
        && !utmFormValues.utmContent
        && !utmFormValues.utmTerm
      ) {
        shopifyRequireFields.push('utmRequired');
      }
    }
    if (!migrateToGraphQL) shopifyRequireFields.push('payoutType');
    requiredFieldConfiguration.requiredFields = requiredFieldConfiguration.requiredFields.concat(shopifyRequireFields);
    const shopifyRequiredFieldsErrorText: { [k: string]: string } = {
      payoutType: 'Please select one of the Payout Options',
      prefixType: 'Please select a Promo Code Unique Naming Strategy',
      payoutOptions: 'Please add payout options details',
      linkedShopifyOfferId: 'Please add a Shopify discount id',
      discountCodeGid: 'Please add a Shopify discount code groud id for secondary stores',
      discountCodeGidIncorrect: 'Please enter a valid discount code gid which is different from parent store',
      utmRequired: 'Please add at least one UTM Parameter',
    };
    if (!linkShopifyOffer) {
      shopifyRequiredFieldsErrorText.priceRuleType = 'Please select one of the Discount Options';
      shopifyRequiredFieldsErrorText.priceRuleAmount = 'Please select one of the Discount Values';
    }
    if (migrateToGraphQL) {
      requiredFieldConfiguration.requiredFields.push('payoutOptions');
      delete shopifyRequiredFieldsErrorText.payoutType;
    }
    requiredFieldConfiguration.requiredFieldsErrorText = {
      ...requiredFieldConfiguration.requiredFieldsErrorText,
      ...shopifyRequiredFieldsErrorText,
    };
  }
  return requiredFieldConfiguration;
};
