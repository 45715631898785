import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ProgramStatusType } from '@services/communities/types';
import {
  HouseChimneyIcon,
  SquareKanbanIcon,
  SidebarIcon,
  UserPlusIcon,
  InboxIcon,
  ChartColumnIcon,
  FolderUserIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@frontend/shadcn/lib/utils';
import Text from '@frontend/app/refresh-components/Text';
import { TopNavItem } from '@frontend/app/types/TopNavItem';
import { Button, buttonVariants } from '@/shadcn/components/ui/button';
import {
  Layout,
  LeftSidebar,
  LeftSidebarContent,
  Navbar,
  SecondaryNav,
  MainContent,
} from '@/shadcn/layouts/double-sidebar';
import { useProjectsApp } from '../Projects/hooks';
import {
  useClientFeatureEnabled,
  useCommunitiesQuery,
  useGetCurrentClient,
  useGetInstalledApplicationIds,
  useGetProfile,
  useTopNavigation,
} from '@/app/hooks';
import { ClientFeature } from '@/app/constants';
import { ProjectByIdQuery_project } from '@/app/queries/types/ProjectByIdQuery';
import { GetCommunitiesQuery_communities } from '@/app/queries/types/GetCommunitiesQuery';
import { MemberSearch } from '@/app/components/MemberSearch/MemberSearchShadCN';
import { useAuth } from '@/context/authContext';
import { UserMenu } from './components/userMenu';
import { SecondaryProjectsNav } from './components/navigations/projectNavigation';
import { SecondaryMembersNav } from './components/navigations/membersNavigation';
import { SecondaryRecruitNav } from './components/navigations/recruitNavigation';
import { SecondaryReportingNav } from './components/navigations/reportNavigation';
import {
  CONTENT_APP_ID,
  FULFILLMENT_APP_ID,
  PAYMENT_APP_ID,
  SALES_APP_ID,
  SOCIAL_DISCOVERY_APP_ID,
  SOCIAL_POST_APP_ID,
  CONTRACT_APP_ID,
} from '@/app/constants/applicationIds';

function useProjects() {
  const { projects } = useProjectsApp();
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);

  const projectsToRender = useMemo(() => {
    const filteredProjects = projects.filter(
      (project) => !isArchiveProjectEnabled || (isArchiveProjectEnabled && project.status === ProjectStatus.Active),
    );
    filteredProjects.sort((a, b) => a.title.localeCompare(b.title));
    return filteredProjects;
  }, [projects, isArchiveProjectEnabled]);
  return projectsToRender;
}

const useGroups = () => {
  const { data: { communities: groups = [] } = {} } = useCommunitiesQuery();
  const groupsToRender = useMemo(() => {
    const groupToSort = [...groups];
    groupToSort && groupToSort.sort((a, b) => a.title.localeCompare(b.title));
    return groupToSort;
  }, [groups]);
  return groupsToRender;
};

function useSidebarVisibility({
  secondaryNavRef,
  mainMenuRef,
  callback,
}: {
  secondaryNavRef: React.RefObject<HTMLElement>;
  mainMenuRef: React.RefObject<HTMLElement>;
  callback: () => void;
}) {
  useEffect(() => {
    function handleMouseMove(event) {
      const { clientX, clientY } = event;

      // Get the bounding rect for both sidebar and main menu
      const sidebarRect = secondaryNavRef.current ? secondaryNavRef.current.getBoundingClientRect() : null;
      const mainMenuRect = mainMenuRef.current ? mainMenuRef.current.getBoundingClientRect() : null;

      // Check if the mouse is within the sidebar or main menu
      const isInSidebar = sidebarRect
        && clientX >= sidebarRect.left
        && clientX <= sidebarRect.right
        && clientY >= sidebarRect.top
        && clientY <= sidebarRect.bottom;

      const isInMainMenu = mainMenuRect
        && clientX >= mainMenuRect.left
        && clientX <= mainMenuRect.right
        && clientY >= mainMenuRect.top
        && clientY <= mainMenuRect.bottom;

      // If cursor is outside the sidebar and main menu
      if (!isInSidebar && !isInMainMenu) {
        callback();
      }
    }

    // Bind the event listener for mouse move
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [secondaryNavRef, mainMenuRef, callback]);
}

function useOutsideAlerter({
  targetRef,
  callback,
  excludeRefs,
}: {
  targetRef: React.RefObject<HTMLElement>;
  excludeRefs?: React.RefObject<HTMLElement>[];
  callback: () => void;
}) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        targetRef.current
        && !targetRef.current.contains(event.target)
        && (!excludeRefs || !excludeRefs.some((excludeRef) => excludeRef.current?.contains(event.target)))
      ) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef, excludeRefs, callback]);
}

type SecondaryNavModes = 'recruit' | 'reporting' | 'projects' | 'members' | undefined;

export type SecondaryNavProps = {
  projects?: ProjectByIdQuery_project[];
  onItemSelected?: () => void;
  groups?: GetCommunitiesQuery_communities[];
  isBudgetAllocationEnabled?: boolean;
  isSocialAdsAnalyticsEnabled?: boolean;
  remainingItems: TopNavItem[];
};

const ProjectStatus = {
  Active: ProgramStatusType.ACTIVE,
  Archived: ProgramStatusType.ARCHIVED,
  Deleted: ProgramStatusType.DELETED,
};

type SecondaryNavMapType = {
  [K in SecondaryNavModes]: React.FC<SecondaryNavProps>;
};
const SecondaryNavMap: SecondaryNavMapType = {
  recruit: SecondaryRecruitNav,
  reporting: SecondaryReportingNav,
  projects: SecondaryProjectsNav,
  members: SecondaryMembersNav,
};
type SecondaryNavFactoryProps = {
  mode: SecondaryNavModes;
} & SecondaryNavProps;
export const SecondaryNavFactory = ({ mode, ...props }: SecondaryNavFactoryProps) => {
  const Component = SecondaryNavMap[mode];
  if (!Component) {
    return null;
  }
  return <Component {...props} />;
};

export const MainLayout = ({ children }) => {
  const secondaryNavRef = useRef(null);
  const projectsRef = useRef(null);
  const membersRef = useRef(null);
  const recruitRef = useRef(null);
  const reportingRef = useRef(null);
  const mainMenuRef = useRef(null); // Reference for the main menu
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = React.useState(false);
  const [secondaryNavMode, setSecondaryNavMode] = React.useState<SecondaryNavModes>();
  const secondaryNavPrevMode = useRef<SecondaryNavModes>();
  const projects = useProjects();
  const groups = useGroups();
  const { logout, user } = useAuth();
  const { profile } = useGetProfile();
  const { client: currentClient } = useGetCurrentClient();
  const pfaV2Enabled = useClientFeatureEnabled(ClientFeature.PFA_V2);
  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const isSocialAdsAnalyticsEnabled = useClientFeatureEnabled(ClientFeature.SOCIAL_ADS_ANALYTICS);
  const installedApps = useGetInstalledApplicationIds();
  const [selectedMenuItem, setSelectedMenuItem] = useState('home');
  // set selected menu item based on the current route path
  const location = useLocation();
  const {
    mainNavItems,
  } = useTopNavigation();
  const reportingMenu = mainNavItems.find((item) => item.id === 'reporting');
  const startIndex = reportingMenu.children.findIndex((item) => item.id === 'influencer_whitelist');
  const remainingItems = reportingMenu.children.slice(startIndex + 1);

  const menuStructure = {
    home: ['/home'],
    projects: ['/projects'],
    members: ['/member_table?communityId', '/communities/new'],
    recruit: ['/member_table?sourcingId=social', '/member_table?sourcingId=shopify', `/app/${SOCIAL_DISCOVERY_APP_ID}`],
    messages: ['/messages'],
    reporting: [
      '/impact_dashboard',
      '/budget/dashboard',
      `/app/${SALES_APP_ID}`,
      `/app/${SOCIAL_POST_APP_ID}`,
      `/app/${CONTENT_APP_ID}`,
      `/app/${PAYMENT_APP_ID}`,
      `/app/${FULFILLMENT_APP_ID}`,
      `/app/${CONTRACT_APP_ID}`,
      '/influencer_whitelist',
    ],
  };

  const findSelectedMenuItem = useCallback((path: string) => {
    // Check if the path includes "/member_table"
    if (path.startsWith('/member_table')) {
      const urlParams = new URLSearchParams(path.split('?')[1]);

      // Check for "sourcingId=social" or "sourcingId=shopify"
      const sourcingId = urlParams.get('sourcingId')?.split(' ')[0];
      if (sourcingId === 'social' || sourcingId === 'shopify') {
        return 'recruit';
      }

      // If not sourcingId=social/shopify, it defaults to members
      return 'members';
    }

    for (const [id, paths] of Object.entries(menuStructure)) {
      if (paths.some((itemPath) => path.startsWith(itemPath))) {
        return id; // Return the menu item ID
      }
    }
    return 'home'; // Default to home if no match is found
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update selected menu item based on location change
  useEffect(() => {
    const selectedItem = findSelectedMenuItem(location.pathname + location.search);
    setSelectedMenuItem(selectedItem);
  }, [location, findSelectedMenuItem]);

  useEffect(() => {
    if ((!isSidebarOpen || secondaryNavPrevMode.current !== secondaryNavMode) && secondaryNavRef.current) {
      secondaryNavRef.current.scrollTop = 0;
    }
    secondaryNavPrevMode.current = secondaryNavMode;
  }, [isSidebarOpen, secondaryNavMode]);

  const onSwitchClient = useCallback((client) => {
    const { location } = window;
    const rootDomain = location.hostname.split('.').slice(-2).join('.');

    if (rootDomain === 'localhost') {
      return location.assign(`/client/${client.id}`);
    }

    const hostname = client.hostname || 'community';

    return location.assign(`${location.protocol}//${hostname}.${rootDomain}/client/${client.id}`);
  }, []);

  const handleClickOutside = useCallback(() => {
    setIsSidebarOpen(false);
  }, [setIsSidebarOpen]);
  useOutsideAlerter({
    targetRef: secondaryNavRef,
    excludeRefs: [projectsRef, membersRef, recruitRef, reportingRef],
    callback: handleClickOutside,
  });

  useSidebarVisibility({
    secondaryNavRef,
    mainMenuRef,
    callback: () => setIsSidebarOpen(false),
  });

  const secondaryNavFactoryProps: SecondaryNavProps = {
    projects,
    onItemSelected: () => {
      setIsSidebarOpen(false);
    },
    groups,
    isBudgetAllocationEnabled,
    isSocialAdsAnalyticsEnabled,
    remainingItems,
  };

  return (
    <Layout>
      <Navbar>
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex justify-start items-center">
            <Button
              size="icon"
              variant="ghost"
              onClick={() => {
                setIsMobileSidebarOpen(!isMobileSidebarOpen);
              }}
              className="sm:hidden mr-3"
            >
              <SidebarIcon className="h-5 w-5" />
              <span className="sr-only">Toggle Menu</span>
            </Button>
            <Link className="flex mr-4" to="/home">
              <img
                className="mr-3 h-8"
                alt="Aspire Logo"
                src="https://storage.googleapis.com/aspirex-static-files/new_logo.png"
              />
            </Link>
          </div>
          <div className="flex items-center lg:order-2 gap-2" />
        </div>
      </Navbar>
      <LeftSidebar isOpen={isMobileSidebarOpen} ref={mainMenuRef}>
        <LeftSidebarContent>
          <div className="flex flex-col items-center justify-between sm:h-full h-[calc(100%-3.5rem)]">
            <ul className="flex flex-col items-center gap-4">
              <li>
                <Link className="flex" to="/home">
                  <img
                    className="h-8"
                    alt="Aspire Logo"
                    src="https://storage.googleapis.com/aspirex-static-files/new_logo.png"
                  />
                </Link>
              </li>
              <li>
                <Link
                  onMouseEnter={() => {
                    setIsMobileSidebarOpen(false);
                    setIsSidebarOpen(false);
                  }}
                  to="/home"
                  className={cn(
                    buttonVariants({ variant: selectedMenuItem === 'home' ? 'default' : 'ghost', size: 'sm' }),
                    'h-14 w-16',
                    'flex flex-col gap-2 items-center justify-center rounded-lg transition-colors text-foreground',
                    selectedMenuItem === 'home'
                    && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white text-primary-foreground hover:text-primary-foreground',
                  )}
                >
                  <HouseChimneyIcon className="h-4 w-4 " />
                  <Text visualVariant="caption">Home</Text>
                </Link>
              </li>
              <li>
                <Button
                  variant="ghost"
                  ref={projectsRef}
                  className={cn(
                    buttonVariants({ variant: selectedMenuItem === 'projects' ? 'default' : 'ghost', size: 'sm' }),
                    'h-14 w-16',
                    'flex flex-col gap-2 items-center justify-center rounded-lg transition-colors text-foreground',
                    selectedMenuItem === 'projects'
                    && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white text-primary-foreground hover:text-primary-foreground',
                  )}
                  onMouseEnter={() => {
                    setSecondaryNavMode('projects');
                    setIsSidebarOpen(secondaryNavMode === 'projects' ? !isSidebarOpen : true);
                  }}
                >
                  <SquareKanbanIcon className="h-4 w-4 " />
                  <Text visualVariant="caption">Projects</Text>
                </Button>
              </li>
              <li>
                <Button
                  variant="ghost"
                  ref={membersRef}
                  className={cn(
                    buttonVariants({ variant: selectedMenuItem === 'members' ? 'default' : 'ghost', size: 'sm' }),
                    'h-14 w-16',
                    'flex flex-col gap-2 items-center justify-center rounded-lg transition-colors text-foreground',
                    selectedMenuItem === 'members'
                    && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white text-primary-foreground hover:text-primary-foreground',
                  )}
                  onMouseEnter={() => {
                    setSecondaryNavMode('members');
                    setIsSidebarOpen(secondaryNavMode === 'members' ? !isSidebarOpen : true);
                  }}
                >
                  <FolderUserIcon className="h-4 w-4 " />
                  <Text visualVariant="caption">Members</Text>
                </Button>
              </li>
              <li>
                <Button
                  variant="ghost"
                  ref={recruitRef}
                  className={cn(
                    buttonVariants({ variant: selectedMenuItem === 'recruit' ? 'default' : 'ghost', size: 'sm' }),
                    'h-14 w-16',
                    'flex flex-col gap-2 items-center justify-center rounded-lg transition-colors text-foreground',
                    selectedMenuItem === 'recruit'
                    && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white text-primary-foreground hover:text-primary-foreground',
                  )}
                  onMouseEnter={() => {
                    setSecondaryNavMode('recruit');
                    setIsSidebarOpen(true);
                  }}
                >
                  <UserPlusIcon className="h-4 w-4" />
                  <Text visualVariant="caption">Recruit</Text>
                </Button>
              </li>
              <li>
                <Link
                  onMouseEnter={() => {
                    setIsMobileSidebarOpen(false);
                    setIsSidebarOpen(false);
                  }}
                  to="/messages"
                  className={cn(
                    buttonVariants({ variant: selectedMenuItem === 'messages' ? 'default' : 'ghost', size: 'sm' }),
                    'h-14 w-16',
                    'flex flex-col gap-2 items-center justify-center rounded-lg transition-colors text-foreground',
                    selectedMenuItem === 'messages'
                    && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white text-primary-foreground hover:text-primary-foreground',
                  )}
                >
                  <InboxIcon className="h-4 w-4" />
                  <Text visualVariant="caption">Inbox</Text>
                </Link>
              </li>
              <li>
                <Button
                  variant="ghost"
                  ref={reportingRef}
                  className={cn(
                    buttonVariants({ variant: selectedMenuItem === 'reporting' ? 'default' : 'ghost', size: 'sm' }),
                    'h-14 w-16',
                    'flex flex-col gap-2 items-center justify-center rounded-lg transition-colors text-foreground',
                    selectedMenuItem === 'reporting'
                    && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white text-primary-foreground hover:text-primary-foreground',
                  )}
                  onMouseEnter={() => {
                    setSecondaryNavMode('reporting');
                    setIsSidebarOpen(secondaryNavMode === 'reporting' ? !isSidebarOpen : true);
                  }}
                >
                  <ChartColumnIcon className="h-4 w-4 " />
                  <div>
                    <Text visualVariant="caption">Reporting</Text>
                  </div>
                </Button>
              </li>
            </ul>
            <div className="flex flex-col items-center gap-4 mt-auto">
              <MemberSearch />
              <UserMenu
                user={user}
                currentClient={currentClient}
                profile={profile}
                pfaV2Enabled={pfaV2Enabled}
                onSwitchClient={onSwitchClient}
                installedApps={installedApps}
                logout={logout}
              />
            </div>
          </div>
        </LeftSidebarContent>
        <SecondaryNav ref={secondaryNavRef} isOpen={isSidebarOpen}>
          <SecondaryNavFactory mode={secondaryNavMode} {...secondaryNavFactoryProps} />
        </SecondaryNav>
      </LeftSidebar>
      <MainContent>{children}</MainContent>
    </Layout>
  );
};
