import { DraftInlineStyleType, RawDraftContentState } from 'draft-js';
import { includes, isEmpty, values } from 'lodash';

import { ISTAComposerMemberFieldIds, MEMBER_FIRST_NAME, STA_FIELD_NAME } from '@affiliates/hooks';
import { SEND_STA_TASK_ID, UserInfoInput } from '@affiliates/types/globalTypes';

import { GetOffersBySearchQuery_offers } from '@affiliates/queries/types/GetOffersBySearchQuery';
import { getVariables, replaceVariables } from '@affiliates/utils';

interface IVariable {
  [key: string]: {
    field: string;
    key: string;
    label: string;
  };
}

export default (
  taskId: SEND_STA_TASK_ID,
  offers: GetOffersBySearchQuery_offers[],
  memberFieldIds: ISTAComposerMemberFieldIds,
  memberPortalFeatureFlagEnabled: boolean,
  migrateToGraphQL?: boolean,
  profile?: UserInfoInput,
  isOfferEdited?: boolean,
  brandName?: String,

) => {
  const linkOffer = offers.find((offer) => !isEmpty(offer.links) && !offer.isPromoLink);
  const promoOffer = offers.find((offer) => !isEmpty(offer.promos));
  const name = profile ? profile.name : '';
  const brand = brandName ? brandName.toString() : '';
  let isMultiplePayoutEmail = false;
  if (promoOffer?.promos?.[0]?.defaultPayoutId) isMultiplePayoutEmail = true;
  else if (linkOffer?.links?.[0]?.defaultPayoutId) isMultiplePayoutEmail = true;

  const variables: IVariable = {
    firstName: {
      field: `MEMBER_FIELDS.${memberFieldIds[MEMBER_FIRST_NAME]}`,
      key: 'FIRST NAME',
      label: 'First Name',
    },
    name: {
      field: name,
      key: 'Name',
      label: 'Name',
    },
    linkOffer: {
      field: linkOffer?.name,
      key: 'linkOffer',
      label: 'linkOffer',
    },
    brandName: {
      field: brand,
      key: 'Brand Name',
      label: 'Brand Name',
    },
  };
  let text: string;
  switch (taskId) {
    case SEND_STA_TASK_ID.send_link_and_promo_task:
      text = 'promo code and link';
      break;
    case SEND_STA_TASK_ID.send_link_task:
      text = 'tracking link';
      break;
    case SEND_STA_TASK_ID.send_promo_task:
      text = 'promo code';
      if (promoOffer.isPromoLink) {
        text = 'shareable link for promo code';
      }
      break;
    default:
      text = '';
  }

  let blocks: RawDraftContentState['blocks'] = [
    {
      key: '8an9f',
      text: `Hello ${variables.firstName.label},`,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 6,
          length: variables.firstName.label.length,
          key: variables.firstName.key,
        },
      ],
      data: {},
    },
    {
      key: '34ho3',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '1dgtl',
      text: isOfferEdited
        ? `This is ${variables.name.field} from ${variables.brandName.field}. We have updated the some details of ${variables.linkOffer.field} that you are a part of.`
        : `Thank you so much for being a part of our program. I’m excited to share a unique ${text} with you.`,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: isOfferEdited
        ? [
          {
            offset: 8,
            length: variables.name.field?.length,
            key: variables.name.key,
          },
          {
            offset: 14 + variables.name.field?.length,
            length: variables.brandName.field?.length,
            key: variables.brandName.key,
          },
          {
            offset: 52 + variables.brandName.field?.length + variables.name.field?.length,
            length: variables.linkOffer.field?.length,
            key: variables.linkOffer.key,
          },
        ]
        : [],
      data: {},
    },
    {
      key: 'd1t0p',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ];

  if (
    !isEmpty(promoOffer)
    && includes([SEND_STA_TASK_ID.send_promo_task, SEND_STA_TASK_ID.send_link_and_promo_task], taskId)
  ) {
    variables.promoCode = {
      field: `MEMBER_FIELDS.${memberFieldIds[STA_FIELD_NAME]}.${promoOffer.id}.PROMOCODE`,
      key: 'PROMO CODE',
      label: `${promoOffer.name} Promo Code`,
    };
    variables.promoCodeDates = {
      field: `MEMBER_FIELDS.${memberFieldIds[STA_FIELD_NAME]}.${promoOffer.id}.ACTIVEDATES`,
      key: 'PROMO CODE ACTIVE DATES',
      label: `${promoOffer.name} Active Dates in PST`,
    };
    if (migrateToGraphQL && isMultiplePayoutEmail) {
      variables.affiliatePayout = {
        field: `MEMBER_FIELDS.${memberFieldIds[STA_FIELD_NAME]}.${promoOffer.id}.AFFILIATEPAYOUT`,
        key: 'PROMO CODE PAYOUTVALUE',
        label: `${promoOffer.name} Payout Value`,
      };
    }
    if (memberPortalFeatureFlagEnabled) {
      variables.promoMemberPageUrl = {
        field: `MEMBER_FIELDS.${memberFieldIds[STA_FIELD_NAME]}.${promoOffer.id}.MEMBERPAGEURL`,
        key: 'PROMO CODE MEMBER PERFORMANCE URL',
        label: `Member Performance URL - ${promoOffer.name}`,
      };
    }
    if (promoOffer.isPromoLink) {
      variables.link = {
        field: `MEMBER_FIELDS.${memberFieldIds[STA_FIELD_NAME]}.${promoOffer.id}.LINK`,
        key: 'LINK',
        label: `Unique shareable link - ${promoOffer.name} link`,
      };
    }

    blocks = blocks
      .concat([
        {
          key: 'cj6tj',
          text: 'Promo Code Details',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [
            {
              style: 'BOLD',
              offset: 0,
              length: 18,
            },
          ],
          entityRanges: [],
          data: {},
        },
      ])
      .concat(
        migrateToGraphQL && isMultiplePayoutEmail
          ? [
              {
                key: '52jir',
                text: `You will receive ${variables.affiliatePayout.label}.`,
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [
                  {
                    offset: 17,
                    length: variables.affiliatePayout.label.length,
                    key: variables.affiliatePayout.key,
                  },
                ],
                data: {},
              },
              {
                key: 'emno4',
                text: `It will be active from ${variables.promoCodeDates.label}. You can then start sharing it with your social channels and it will give each person __DISCOUNT_LABEL__ off their purchases when they check out.`,
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
            ]
          : [
              {
                key: 'emno4',
                text: `__PAYOUT_LABEL__ It will be active from ${variables.promoCodeDates.label}. You can then start sharing it with your social channels and it will give each person __DISCOUNT_LABEL__ off their purchases when they check out.`,
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
            ],
      )
      .concat(
        ...(promoOffer.isPromoLink
          ? [
              {
                key: '7udia2',
                text: '',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
              {
                key: '52ji2',
                text: 'Your unique shareable link:',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [
                  {
                    style: 'BOLD' as DraftInlineStyleType,
                    offset: 0,
                    length: 27,
                  },
                ],
                entityRanges: [],
                data: {},
              },
              {
                key: '52ji3',
                text: variables.link.label,
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [
                  {
                    offset: 0,
                    length: variables.link.label.length,
                    key: variables.link.key,
                  },
                ],
                data: {},
              },
              {
                key: '8ud3a1',
                text: '',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
            ]
          : []),
      )
      .concat((!promoOffer?.promos[0].isSecureCodes ? [
        {
          key: '7udia',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: '52jid',
          text: 'Your unique promo code:',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [
            {
              style: 'BOLD',
              offset: 0,
              length: 23,
            },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: '52jie',
          text: variables.promoCode.label,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [
            {
              offset: 0,
              length: variables.promoCode.label.length,
              key: variables.promoCode.key,
            },
          ],
          data: {},
        },
        {
          key: '8ud3a',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ] : []))
      .concat(
        memberPortalFeatureFlagEnabled
          ? [
              {
                key: '6vu8u',
                text: 'Use the link below for viewing your offer information and performance for this promo code:',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [
                  {
                    style: 'BOLD',
                    offset: 0,
                    length: 90,
                  },
                ],
                entityRanges: [],
                data: {},
              },
              {
                key: '6vu8w',
                text: variables.promoMemberPageUrl.label,
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [
                  {
                    offset: 0,
                    length: variables.promoMemberPageUrl.label.length,
                    key: variables.promoMemberPageUrl.key,
                  },
                ],
                data: {},
              },
              {
                key: 'a1d7c',
                text:
                  '(Important: this link is personalized to show your performance only, please do not share it with anyone else that you do not want accessing this information.)',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [
                  {
                    style: 'BOLD',
                    offset: 1,
                    length: 10,
                  },
                ],
                entityRanges: [],
                data: {},
              },
              {
                key: 'a1d8a',
                text: '',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
              },
            ]
          : [],
      )
      .concat([
        {
          key: 'fk41k',
          text: 'Please note:',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [
            {
              style: 'BOLD',
              offset: 0,
              length: 12,
            },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: 'fp1p9',
          text: 'We will track conversions and sales associated to this code.',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: '9ud5a',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ]);

    const variablesFromOffer = getVariables(promoOffer);
    blocks.forEach((block) => {
      block.text = replaceVariables(block.text, variablesFromOffer);
    });
    const promoActiveDateBlockIndex = blocks.findIndex((b) => b.key === 'emno4');
    if (promoActiveDateBlockIndex >= 0) {
      blocks[promoActiveDateBlockIndex].entityRanges.push({
        offset: blocks[promoActiveDateBlockIndex].text.indexOf(variables.promoCodeDates.label),
        length: variables.promoCodeDates.label.length,
        key: variables.promoCodeDates.key,
      });
    }
  }

  if (
    !isEmpty(linkOffer)
    && includes([SEND_STA_TASK_ID.send_link_task, SEND_STA_TASK_ID.send_link_and_promo_task], taskId)
  ) {
    variables.affiliateLink = {
      field: `MEMBER_FIELDS.${memberFieldIds[STA_FIELD_NAME]}.${linkOffer.id}.LINK`,
      key: 'SALES LINK',
      label: `${linkOffer.name} Link`,
    };
    if (memberPortalFeatureFlagEnabled) {
      variables.linkMemberPageUrl = {
        field: `MEMBER_FIELDS.${memberFieldIds[STA_FIELD_NAME]}.${linkOffer.id}.MEMBERPAGEURL`,
        key: 'LINK OFFER MEMBER PERFORMANCE URL',
        label: `Member Performance URL - ${linkOffer.name}`,
      };
    }
    if (migrateToGraphQL && isMultiplePayoutEmail) {
      variables.affiliatePayoutLink = {
        field: `MEMBER_FIELDS.${memberFieldIds[STA_FIELD_NAME]}.${linkOffer.id}.AFFILIATEPAYOUTLINK`,
        key: 'LINK CODE PAYOUTVALUE',
        label: `${linkOffer.name} Payout Value link`,
      };
    }

    blocks = blocks.concat([
      {
        key: '1ko5j',
        text: 'Tracking Link Details',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [
          {
            style: 'BOLD',
            offset: 0,
            length: 21,
          },
        ],
        entityRanges: [],
        data: {},
      },
    ])
    .concat(migrateToGraphQL && isMultiplePayoutEmail ? [
        {
          key: '52jis',
          text: `You will receive ${variables.affiliatePayoutLink.label}.`,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [
            {
              offset: 17,
              length: variables.affiliatePayoutLink.label.length,
              key: variables.affiliatePayoutLink.key,
            },
          ],
          data: {},
        },
      ] : [
        {
          key: '3ab3p',
          text:
            '__PAYOUT_LABEL__',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ])
      .concat([
        {
          key: '4fgtr',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: '6vu6u',
          text: 'Your unique link:',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [
            {
              style: 'BOLD',
              offset: 0,
              length: 17,
            },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: '6vu6w',
          text: variables.affiliateLink.label,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [
            {
              offset: 0,
              length: variables.affiliateLink.label.length,
              key: variables.affiliateLink.key,
            },
          ],
          data: {},
        },
        {
          key: 'a1d9a',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ])
      .concat(
        memberPortalFeatureFlagEnabled ? [
          {
            key: '6vu7u',
            text: 'Use the link below for viewing your offer information and performance for this affiliate link:',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [
              {
                style: 'BOLD',
                offset: 0,
                length: 94,
              },
            ],
            entityRanges: [],
            data: {},
          },
          {
            key: '6vu7w',
            text: variables.linkMemberPageUrl.label,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [
              {
                offset: 0,
                length: variables.linkMemberPageUrl.label.length,
                key: variables.linkMemberPageUrl.key,
              },
            ],
            data: {},
          },
          {
            key: 'a1d7b',
            text: '(Important: this link is personalized to show your performance only, please do not share it with anyone else that you do not want accessing this information.)',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [
              {
                style: 'BOLD',
                offset: 1,
                length: 10,
              },
            ],
            entityRanges: [],
            data: {},
          },
          {
            key: 'a1d7a',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ] : [],
      )
      .concat([
        {
          key: 'b7bun',
          text: 'Please note:',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [
            {
              style: 'BOLD',
              offset: 0,
              length: 12,
            },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: '5unp6',
          text:
            '* We use Aspire to track clicks and conversions. As long as you hyperlink the URL, it is essentially invisible to the customer as it redirects immediately to our landing page.',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: '2f8jf',
          text:
            '* Feel free to put the unique link through a bit.ly generator or other URL shortener to share a friendlier link in your bio.',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: '70ems',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ]);

    const variablesFromOffer = getVariables(linkOffer);
    blocks.forEach((block) => {
      block.text = replaceVariables(block.text, variablesFromOffer);
    });
  }

  const entityMap: RawDraftContentState['entityMap'] = {};
  for (const variable of values(variables)) {
    entityMap[variable.key] = {
      type: 'variable',
      mutability: 'IMMUTABLE',
      data: {
        data: {
          field: variable.field,
        },
      },
    };
  }
  return { blocks, entityMap };
};
