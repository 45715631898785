import React from 'react';
import { Card } from '@frontend/shadcn/components/ui/card';
import { ChartBarIcon } from '@revfluence/fresh-icons/regular/esm';
import { formatNumber } from '../../../utils/formatNumber';
import {
  HighlightCardHeader,
  HighlightCardTitle,
  HighlightCardContent,
  HighlightCardContentRow,
  HighlightCardContentColumn,
  HighlightCardStatRow,
  HighlightCardStat,
  HighlightCardStatLabel,
} from '../../../components/highlight-cards';

const ReachCard = () => {
  const organicReach = 45678;
  const paidReach = 12345;

  return (
    <Card>
      <HighlightCardHeader className="bg-green-6">
        <HighlightCardTitle>
          <ChartBarIcon className="mr-2" />
          <span>Reach</span>
        </HighlightCardTitle>
      </HighlightCardHeader>
      <HighlightCardContent>
        <HighlightCardContentRow>
          <HighlightCardContentColumn className="col-span-3">
            <HighlightCardStatRow>
              <HighlightCardStat className="text-3xl leading-none">{formatNumber(organicReach)}</HighlightCardStat>
            </HighlightCardStatRow>
            <HighlightCardStatLabel>Organic</HighlightCardStatLabel>
          </HighlightCardContentColumn>
          <HighlightCardContentColumn className="col-span-2">
            <HighlightCardStatRow>
              <HighlightCardStat className="leading-none">{formatNumber(paidReach)}</HighlightCardStat>
            </HighlightCardStatRow>
            <HighlightCardStatLabel>Paid</HighlightCardStatLabel>
          </HighlightCardContentColumn>
        </HighlightCardContentRow>
      </HighlightCardContent>
    </Card>
  );
};

export default ReachCard;
