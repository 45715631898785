import React from 'react';
import { cn } from '@frontend/shadcn/lib/utils';
import { CardHeader, CardTitle, CardContent } from '@frontend/shadcn/components/ui/card';
import { CardStat, CardStatLabel, CardStatRow } from '../card-stat';

export const HighlightCardHeader = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardHeader>>(
  ({ className, ...props }, ref) => (
    <CardHeader ref={ref} className={cn('rounded-t-2xl p-4 pb-0', className)} {...props} />
  ),
);
HighlightCardHeader.displayName = 'HighlightCardHeader';

export const HighlightCardTitle = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardTitle>>(
  ({ className, ...props }, ref) => (
    <CardTitle ref={ref} className={cn('text-primary-foreground flex items-center', className)} {...props} />
  ),
);
HighlightCardTitle.displayName = 'HighlightCardTitle';

export const HighlightCardContent = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardContent>>(
  ({ className, ...props }, ref) => <CardContent ref={ref} className={cn('p-2 px-4', className)} {...props} />,
);
HighlightCardContent.displayName = 'HighlightCardContent';

export const HighlightCardContentRow = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-end flex-wrap gap-2 justify-between', className)} {...props} />
  ),
);
HighlightCardContentRow.displayName = 'HighlightCardContentRow';

export const HighlightCardContentColumn = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ className, ...props }, ref) => <div ref={ref} className={cn('flex flex-col items-start', className)} {...props} />,
);
HighlightCardContentColumn.displayName = 'HighlightCardContentColumn';

export const HighlightCardStatRow = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardStatRow>>(
  ({ className, ...props }, ref) => <CardStatRow ref={ref} className={className} {...props} />,
);
HighlightCardStatRow.displayName = 'HighlightCardStatRow';

export const HighlightCardStat = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardStat>>(
  ({ className, ...props }, ref) => <CardStat ref={ref} className={cn('text-xl', className)} {...props} />,
);
HighlightCardStat.displayName = 'HighlightCardStat';

export const HighlightCardStatLabel = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof CardStatLabel>>(
  ({ className, ...props }, ref) => <CardStatLabel ref={ref} className={className} {...props} />,
);
HighlightCardStatLabel.displayName = 'HighlightCardStatLabel';
