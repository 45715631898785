import React from 'react';
import { Card } from '@frontend/shadcn/components/ui/card';
import { UsersIcon } from '@revfluence/fresh-icons/regular/esm';
import { formatNumberDefault } from '../../../utils/formatNumber';
import {
  HighlightCardHeader,
  HighlightCardTitle,
  HighlightCardContent,
  HighlightCardContentRow,
  HighlightCardContentColumn,
  HighlightCardStatRow,
  HighlightCardStat,
  HighlightCardStatLabel,
} from '../../../components/highlight-cards';

const MembersCard = () => {
  const totalMembers = 1234;

  return (
    <Card>
      <HighlightCardHeader className="bg-blue-6">
        <HighlightCardTitle>
          <UsersIcon className="mr-2" />
          <span>Members</span>
        </HighlightCardTitle>
      </HighlightCardHeader>
      <HighlightCardContent>
        <HighlightCardContentRow>
          <HighlightCardContentColumn>
            <HighlightCardStatRow>
              <HighlightCardStat className="text-3xl leading-none">
                {formatNumberDefault(totalMembers)}
              </HighlightCardStat>
            </HighlightCardStatRow>
            <HighlightCardStatLabel>Total Active Members</HighlightCardStatLabel>
          </HighlightCardContentColumn>
        </HighlightCardContentRow>
      </HighlightCardContent>
    </Card>
  );
};

export default MembersCard;
