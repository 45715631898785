import * as React from 'react';
import {
  capitalize,
  filter, isEmpty, isNull, isString, map, size, trim,
} from 'lodash';

import {
  IRowData,
} from '@components';

import { ExportButton } from '@affiliates/components/MemberTable/Buttons';
import { IHeader, useDashboardQueries } from '@affiliates/hooks';
import { IEnabledOfferSources } from '@affiliates/types';
import { format } from 'date-fns';
import {
  Button,
  Checkbox,
  Col, Dropdown, IColumnsType, Input, Row, Select, Tag, Tooltip, Typography,
} from '@revfluence/fresh';
import { useState } from 'react';
import _ from 'lodash';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { GetAllPrograms_programs } from '@frontend/app/queries/types/GetAllPrograms';
import { ChevronLeftIcon, ChevronRightIcon } from '@revfluence/fresh-icons/regular/esm';
import { BoxArchiveIcon } from '@revfluence/fresh-icons/regular';
import { IDateRangeSettings } from '@frontend/app/components';
import { AddOfferButton } from './AddOfferButton';
import { OfferNameCell } from './OfferNameCell';
import { GetStarted } from './GetStarted';
import {
  IDashboardOffer,
  IOfferTableCsvRow,
  IOfferTableRow,
  OFFER_STATUS,
  TOnClickAddOffer,
} from './types';

import AffiliateTableSTA from '../AffiliateTableSTA/AffiliateTableSTA';
import { arrangeTableColumns } from '../../utils/columnOrder';
import { TableHeader } from '../TableHeader';
import { getTagType } from '../../utils/getTagType';
import Styles from './OfferTable.scss';
import { DataFormat, formatValue } from '../../utils';
import { useClientFeature } from '../../contexts/ClientFeatureContext';
import { OFFER_STATUS_TYPE, STATS_OFFER_TYPE } from '../../types/globalTypes';
import { RefreshGetStarted } from './RefreshGetStarted';

const { useMemo, useCallback } = React;
const { Link } = Typography;
interface IProps {
  data: readonly IDashboardOffer[];
  missingShopifyCredentials: boolean;
  onClickAddOffer: TOnClickAddOffer;
  onClickExport: (columns: IHeader[], data: IOfferTableCsvRow[]) => void;
  onOfferClicked(offerId: number);
  sources: IEnabledOfferSources;
  programs: GetAllPrograms_programs[];
  baseUri: string;
  clientName: string;
  dateRangeSettings: IDateRangeSettings;
  selectedOfferType: STATS_OFFER_TYPE;
  setSelectedOfferType: (value: STATS_OFFER_TYPE) => void;
  selectedFilters: OFFER_STATUS_TYPE[];
  setSelectedFilters: (value: OFFER_STATUS_TYPE[]) => void;
}

const asOfferStatus = (status: string, expired: boolean, archived: boolean) => {
  const isActive = status === OFFER_STATUS.ACTIVE;
  const isPaused = status === OFFER_STATUS.PAUSED;
  const isDeleted = status === OFFER_STATUS.DELETED;
  const isExpired = expired;
  if (archived) {
    return OFFER_STATUS.ARCHIVED;
  } if (isExpired) {
    return OFFER_STATUS.EXPIRED;
  } if (isActive) {
    return OFFER_STATUS.ACTIVE;
  } if (isPaused) {
    return OFFER_STATUS.PAUSED;
  } if (isDeleted) {
    return OFFER_STATUS.DELETED;
  }
  throw new Error(`Invalid offer status: "${status}".`);
};
const PAGE_SIZE = 100;
export const OfferTable: React.FC<Readonly<IProps>> = (props) => {
  const {
    data,
    missingShopifyCredentials,
    onClickAddOffer,
    onClickExport,
    onOfferClicked,
    sources,
    programs,
    baseUri,
    clientName,
    dateRangeSettings,
    selectedOfferType,
    setSelectedOfferType,
    selectedFilters,
    setSelectedFilters,
  } = props;
  const [searchText, setSearchText] = useState('');
  const [resetKey, setResetKey] = useState(0);
  const [filterVisible, setFilterVisible] = useState(false);
  const { archiveOffer, refreshUi } = useClientFeature();
  const [tempSelectedFilters, setTempSelectedFilters] = useState([...selectedFilters]);

  const handleReset = () => {
    setResetKey((prevKey) => prevKey + 1);
  };

  const handleOfferChange = useCallback((value) => {
    if (value === '') {
      handleReset();
    }
    const offerType = STATS_OFFER_TYPE[value as keyof typeof STATS_OFFER_TYPE] || value;
    setSelectedOfferType(offerType);
  }, [setSelectedOfferType]);

  useDashboardQueries(
    baseUri,
    clientName,
    dateRangeSettings,
    sources,
    selectedOfferType,
    selectedFilters,
    archiveOffer,
  );

  const mapProgramById = useCallback(
    (id: number): string => {
      const program = _.find(programs, (program) => program.id === id);
      return program ? program?.title : '-';
    },
    [programs],
  );
  const renderNameField = useCallback((data) => (
    <OfferNameCell
      imageUrl={data.imageUrl}
      name={data.name}
      source={data.source}
    />
  ), []);
  const columnConfig: IColumnsType<IOfferTableRow> = useMemo(() => ([
    {
      title: 'Name',
      dataIndex: 'offerName',
      key: 'offerName',
      render: renderNameField,
      width: 300,
      align: 'left',
      sorter: (a, b) => a._sortableFields.offerName.toString().localeCompare(b._sortableFields.offerName.toString()),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'Connected Project',
      dataIndex: 'programId',
      key: 'programId',
      render: (data) => mapProgramById(data),
      align: 'left',
      width: 180,
      sorter: (a, b) => mapProgramById(a.programId).localeCompare(mapProgramById(b.programId)),
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        text ? (
          <Tooltip placement="topLeft" title={text}>
            <Link>{text}</Link>
            {' '}
          </Tooltip>
        ) : '-'
      ),
      align: 'left',
      sorter: (a, b) => a.url.localeCompare(b.url),
    },
    {
      title: 'Members',
      dataIndex: 'members',
      key: 'members',
      align: 'left',
      width: 120,
      render: (text) => formatValue(DataFormat.Integer, text),
      sorter: (a, b) => a.members - b.members,
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      align: 'left',
      width: 120,
      render: (text) => formatValue(DataFormat.Integer, text),
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: 'Conversions',
      dataIndex: 'conversions',
      key: 'conversions',
      align: 'left',
      width: 120,
      render: (text) => formatValue(DataFormat.Integer, text),
      sorter: (a, b) => a.conversions - b.conversions,
    },
    {
      title: 'Sales Amt',
      dataIndex: 'salesAmount',
      key: 'salesAmount',
      align: 'left',
      width: 120,
      render: (text) => `$${formatValue(DataFormat.Money, text)}`,
      sorter: (a, b) => a.salesAmount - b.salesAmount,
    },
    {
      title: 'Commissions Earned ',
      dataIndex: 'payoutEarned',
      key: 'payoutEarned',
      align: 'left',
      width: 150,
      render: (text) => `$${formatValue(DataFormat.Money, text)}`,
      sorter: (a, b) => a.payoutEarned - b.payoutEarned,
    },
    {
      title: 'Payout',
      dataIndex: 'payoutMade',
      key: 'payoutMade',
      align: 'left',
      width: 120,
      render: (text) => `$${formatValue(DataFormat.Money, text)}`,
      sorter: (a, b) => a.payoutMade - b.payoutMade,
    },
    {
      title: 'Avg. Sale',
      dataIndex: 'avgSale',
      key: 'avgSale',
      align: 'left',
      width: 120,
      render: (text) => `$${formatValue(DataFormat.Money, text)}`,
      sorter: (a, b) => a.avgSale - b.avgSale,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'left',
      width: 120,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => <Tag icon={status === 'ARCHIVED' ? <BoxArchiveIcon /> : ''} color={getTagType(status)}>{capitalize(status)}</Tag>,
    },
    {
      title: 'Created On',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'left',
      width: 180,
      render: (text) => format(new Date(text), 'MM/dd/yyyy, hh:mm'),
      sorter: (a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
    },
  ]), [renderNameField, mapProgramById]);

  const tableData = useMemo(() => map(data, (offer): IOfferTableRow => {
    const row = {
      avgSale: offer.avgSale,
      clicks: offer.clicks,
      conversions: offer.conversions,
      id: String(offer.offerId),
      members: offer.members,
      offerName: {
        name: offer.offerName,
        imageUrl: offer.offerImageUrl,
        source: offer.source,
      },
      payoutEarned: offer.payoutEarned,
      payoutMade: offer.payoutMade,
      salesAmount: offer.salesAmount,
      status: asOfferStatus(offer.status, offer.expired, !isNull(offer.archivedDate)),
      url: offer.url,
      _raw: offer,
      _sortableFields: {
        offerName: trim(offer.offerName).toLowerCase(),
      },
      createdDate: offer.createdDate,
      programId: offer.programId,
      offerType: offer.offerType,
      isOfferArchived: !isNull(offer.archivedDate),

    };
    return row;
  }), [data]);

  const filteredData = useMemo(() => tableData.filter((item) => {
      // Check Offer Type filter
      const isOfferTypeMatched = selectedOfferType === 'ALL_OFFERS'
        || (selectedOfferType === 'LINK_OFFER' && item.offerType === 'LINK')
        || (selectedOfferType === 'PROMO_OFFER' && item.offerType === 'PROMO_CODE');

      // Check Status filter
      const isStatusMatched = selectedFilters.length === 0 || selectedFilters.some((status) => status.toUpperCase() === item.status);

      // Check Search Text filter
      const isSearchTextMatched = searchText.trim() === ''
        || (isString(item.offerName.name) && item.offerName.name.toLowerCase().includes(searchText.toLowerCase()));
      // Return only records that match all filters
      return isOfferTypeMatched && isStatusMatched && isSearchTextMatched;
    }), [tableData, selectedOfferType, selectedFilters, searchText]);

    // Handle checkbox change - this only updates the temporary state
    const handleTempFilterChange = (values) => {
      setTempSelectedFilters(values);
    };

    // Apply the selected filters - this is where you update the main state
    const applyFilters = () => {
      setSelectedFilters(tempSelectedFilters); // Apply the temporary selected filters to the main state
      setFilterVisible(false);
    };

    // Reset to the initial filters (defaults)
    const resetFilters = () => {
      const defaultFilters = [
        OFFER_STATUS_TYPE.ACTIVE,
      ];

      setTempSelectedFilters(defaultFilters);
      setSelectedFilters(defaultFilters);
    };

  // Filter options
  const filterOptions = [
    { label: OFFER_STATUS_TYPE.ACTIVE, value: OFFER_STATUS_TYPE.ACTIVE },
    { label: OFFER_STATUS_TYPE.PAUSED, value: OFFER_STATUS_TYPE.PAUSED },
    { label: OFFER_STATUS_TYPE.EXPIRED, value: OFFER_STATUS_TYPE.EXPIRED },
    ...(archiveOffer ? [{ label: OFFER_STATUS_TYPE.ARCHIVED, value: OFFER_STATUS_TYPE.ARCHIVED }] : []),
  ];

  const menuItems = [
    {
      label: (
        <div className="flex flex-col gap-2">
          <Checkbox.Group
            options={filterOptions}
            value={tempSelectedFilters} // Use tempSelectedFilters to track changes
            onChange={handleTempFilterChange} // Update tempSelectedFilters on checkbox change
            className="flex flex-col gap-2"
            style={{ display: 'flex', alignItems: 'flex-start' }}
          />
          <div className="flex gap-2 mt-2">
            <Button onClick={applyFilters} type="primary">
              Apply
            </Button>
            <Button type="default" onClick={resetFilters}>
              Reset
            </Button>
          </div>
        </div>
      ),
      key: 'filters',
    },
  ];

  const searchBox = () => (
    <Row gutter={9}>
      <Col className={Styles.offerFilter}>
        <Select defaultValue={selectedOfferType} onChange={handleOfferChange}>
          <Select.Option value="ALL_OFFERS">All Offers</Select.Option>
          {sources.TUNE && <Select.Option value="LINK_OFFER">Sales Links</Select.Option>}
          {sources.SHOPIFY && <Select.Option value="PROMO_OFFER">Promo Codes</Select.Option>}
        </Select>
      </Col>
      <Col>
        <Dropdown
          menu={{ items: menuItems }}
          trigger={['click']}
          open={filterVisible}
          onOpenChange={(visible) => setFilterVisible(visible)}
        >
          <Button icon={<FilterOutlined />} className="flex items-center" />
        </Dropdown>
      </Col>
      <Col>
        <Input
          placeholder="Search Offer"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          prefix={<SearchOutlined />}
        />
      </Col>
      <Col>
        <ExportButton
          disabled={isEmpty(data)}
          onClick={handleClickExport}
          showText={false}
        />
      </Col>
    </Row>
  );
  const handleRowClicked = useCallback((rowData: IRowData) => {
    onOfferClicked(Number(rowData.id));
  }, [onOfferClicked]);
  const handleClickExport = useCallback(() => {
    const csvColumnConfig = map(columnConfig, (config): IHeader | null => {
      if (!isString(config.title)) {
        return null;
      }
      return {
        headerName: config.title,
        field: config.key.toString(),
      };
    }).filter((c) => !isNull(c));
    const csvData = map(
      // filter out deleted offers from the csv
      filter(filteredData, (row) => row.status !== OFFER_STATUS.DELETED),
      (row) => ({
        ...row,
        offerName: row.offerName.name,
        createdDate: format(new Date(row.createdDate), 'MM/dd/yyy, hh:mm'),
        programId: mapProgramById(row.programId),

      }),
    );
    if (!isEmpty(csvData)) {
      onClickExport(csvColumnConfig, csvData);
    }
  }, [columnConfig, onClickExport, filteredData, mapProgramById]);

  const emptyPlaceholder = useCallback(() => (refreshUi ? (
    <RefreshGetStarted onClickCreate={onClickAddOffer} sources={sources} />
    ) : (
      <GetStarted onClickCreate={onClickAddOffer} sources={sources} />
    )), [onClickAddOffer, sources, refreshUi]); // Include refreshUi in dependencies

  const headerActions = useMemo(() => (
    <TableHeader
      renderCount={() => {
        const offerCount = size(filteredData);
        return `${offerCount} Offer${offerCount !== 1 ? 's' : ''}`;
    }}
    >
      <Row gutter={8}>
        <Col>
          <AddOfferButton
            disabled={false}
            missingShopifyCredentials={missingShopifyCredentials}
            onClick={onClickAddOffer}
            sources={sources}
          />
        </Col>
      </Row>
    </TableHeader>
), [filteredData, missingShopifyCredentials, onClickAddOffer, sources]);

  if (isEmpty(tableData)) {
    return emptyPlaceholder();
  }
  const pagination = {
    total: filteredData.length,
    pageSize: PAGE_SIZE,
    showSizeChanger: false,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    itemRender: (_current, type, originalElement) => {
      if (type === 'prev') {
        return <Button type="ghost" icon={<ChevronLeftIcon fontSize={24} />} />;
      }
      if (type === 'next') {
        return <Button type="ghost" icon={<ChevronRightIcon fontSize={24} />} />;
      }
      return originalElement;
    },
    showLessItems: true,
    className: 'customPagination',
  };

  return (
    <AffiliateTableSTA<IOfferTableRow>
      dataSource={filteredData}
      columns={arrangeTableColumns(columnConfig)}
      headerActions={headerActions}
      onRow={(record) => ({
        onClick: () => handleRowClicked(record),
      })}
      searchBox={searchBox()}
      searchText={searchText}
      sortField="name"
      className="offerTable"
      enableEditColumn
      key={resetKey}
      pagination={pagination}

    />
  );
};
