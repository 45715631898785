import React, { useState } from 'react';
import { cva } from 'class-variance-authority';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuSub,
} from '@frontend/shadcn/components/ui/navigation-menu';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@frontend/shadcn/components/ui/collapsible';
import { P } from '@frontend/shadcn/components/typography/p';
import { isUndefined } from 'lodash';
import { Badge } from 'antd';
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@revfluence/fresh-icons/solid/esm';
import { InstagramMenuAlert } from '../components';

export interface TreeItem {
  id: string;
  title: string;
  count?: number;
  icon?: React.ReactNode;
  onClick?: (id: TreeItem['id']) => void;
  path?: string;
  badge?: boolean;
}

export interface TreeItemGroup extends TreeItem {
  items?: Omit<TreeItem, 'onClick' | 'icon'>[];
  collapsible?: boolean;
}

interface NavigationPanelProps {
  items: (TreeItemGroup | TreeItem)[];
  selectedId?: string;
  onItemClick?: (id: string, path?: string) => void;
}
const newFeatureSVG = require('@frontend/app/assets/svgs/newFeature.svg');

const menuListItemVariants = (hasIcon: boolean) =>
  cva(
    'flex gap-2 pr-3 py-2 w-full mx-0 cursor-pointer rounded-md font-medium text-foreground text-base leading-relaxed items-center',
    {
      variants: {
        placement: {
          root: 'pl-4',
          sub: `justify-between font-normal ${hasIcon ? 'pl-4' : 'pl-12'}`,
        },
        active: {
          true: 'bg-menu-active',
        },
      },
    },
  );

const MainItem: React.FC<{
  item: TreeItem;
  placement: 'root' | 'sub';
  onItemClick?: (id: string, path?: string) => void;
}> = ({ item, placement, onItemClick }) => {
  const handleClick = () => {
    onItemClick?.(item.id, item.path);
  };

  return (
    <>
      {item.icon && <span className="flex px-1">{item.icon}</span>}
      <P
        className={placement === 'sub' ? 'font-normal' : 'font-medium'}
        onClick={handleClick}
      >
        {item.title === 'Instagram (Meta)' ? (
          <InstagramMenuAlert>
            <span className="mr-2">{item.title}</span>
          </InstagramMenuAlert>
        ) : item.title === 'New Features' ? (
          <span>
            {item.title}
            <img src={newFeatureSVG} alt="New Features" className="ml-2" />
          </span>
        ) : (
          item.title
        )}
      </P>
      {!isUndefined(item.count) && item.count > 0 && (
        <span className="ml-auto text-sm whitespace-nowrap">
          {item.count}
          {item.badge && <Badge dot style={{ fontSize: '8px' }} />}
        </span>
      )}
    </>
  );
};

const SubItems: React.FC<{
  items: TreeItem[];
  renderTreeItem: (item: TreeItem | TreeItemGroup, placement: 'root' | 'sub') => React.ReactNode;
}> = ({ items, renderTreeItem }) => (
  <NavigationMenuSub>
    <NavigationMenuList className="flex flex-col gap-1">
      {items.map((subItem) => renderTreeItem(subItem, 'sub'))}
    </NavigationMenuList>
  </NavigationMenuSub>
);

const ExpandedItem: React.FC<{
  item: TreeItemGroup;
  placement: 'root' | 'sub';
  renderTreeItem: (item: TreeItem | TreeItemGroup, placement: 'root' | 'sub') => React.ReactNode;
  onItemClick: (id: string, path?: string) => void;
  isActive: boolean;
}> = ({
  item, placement, renderTreeItem, onItemClick, isActive,
}) => (
  <NavigationMenuItem key={item.id} className="w-full">
    <div className={menuListItemVariants(!!item.icon)({ placement, active: isActive })}>
      <MainItem item={item} placement={placement} onItemClick={onItemClick} />
    </div>
    <SubItems items={item.items || []} renderTreeItem={renderTreeItem} />
  </NavigationMenuItem>
  );

const CollapsibleItem: React.FC<{
  item: TreeItemGroup;
  placement: 'root' | 'sub';
  isOpen: boolean;
  toggleSection: (id: string) => void;
  renderTreeItem: (item: TreeItem | TreeItemGroup, placement: 'root' | 'sub') => React.ReactNode;
}> = ({
  item, placement, isOpen, toggleSection, renderTreeItem,
}) => (
  <NavigationMenuItem key={item.id} className="w-full">
    <Collapsible open={isOpen} onOpenChange={() => toggleSection(item.id)}>
      <CollapsibleTrigger className={menuListItemVariants(!!item.icon)({ placement })}>
        <MainItem item={item} placement={placement} />
        {isOpen ? <ChevronUpIcon className="ml-auto h-4 w-4" /> : <ChevronDownIcon className="ml-auto h-4 w-4" />}
      </CollapsibleTrigger>
      <CollapsibleContent>
        <SubItems items={item.items || []} renderTreeItem={renderTreeItem} />
      </CollapsibleContent>
    </Collapsible>
  </NavigationMenuItem>
  );

const SingleItem: React.FC<{
  item: TreeItem;
  placement: 'root' | 'sub';
  isActive: boolean;
  onItemClick: (id: string, path?: string) => void;
}> = ({
  item, placement, isActive, onItemClick,
}) => {
    const handleClick = () => {
      onItemClick(item.id, item.path);
    };
    return (
      <NavigationMenuItem
        key={item.id}
        className={menuListItemVariants(!!item.icon)({
          placement,
          active: isActive,
        })}
        onClick={handleClick}
      >
        <MainItem item={item} placement={placement} />
      </NavigationMenuItem>
    );
  };

const NavigationPanel: React.FC<NavigationPanelProps> = ({ items, selectedId, onItemClick }) => {
  const [openSections, setOpenSections] = useState<string[]>(() =>
    items.filter((item): item is TreeItemGroup => 'items' in item && item.collapsible).map((item) => item.id));

  const toggleSection = (id: string) => {
    setOpenSections((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]));
  };

  const renderTreeItem = (item: TreeItem | TreeItemGroup, placement: 'root' | 'sub' = 'root') => {
    const isGroup = 'items' in item;
    const isCollapsible = isGroup && item.collapsible;
    const hasItems = isGroup && item.items && item.items.length > 0;
    const showSubItemsByDefault = isGroup && isCollapsible && !isUndefined(item.count);

    if (isGroup && showSubItemsByDefault) {
      return (
        <ExpandedItem
          item={item}
          placement={placement}
          renderTreeItem={renderTreeItem}
          onItemClick={onItemClick}
          isActive={selectedId === item.id}
        />
      );
    }

    if (isGroup && isCollapsible && isUndefined(item.count) && hasItems) {
      const isOpen = openSections.includes(item.id);
      return (
        <CollapsibleItem
          item={item}
          placement={placement}
          isOpen={isOpen}
          toggleSection={toggleSection}
          renderTreeItem={renderTreeItem}
        />
      );
    }

    return (
      <SingleItem
        item={item}
        placement={placement}
        isActive={selectedId === item.id}
        onItemClick={onItemClick || (() => { })}
      />
    );
  };

  return (
    <NavigationMenu
      orientation="vertical"
      className="w-full bg-grey-1 min-w-60 p-2 h-full flex flex-col items-stretch justify-start hover:overflow-y-scroll hover:pr-0"
    >
      <NavigationMenuList className="flex flex-col space-y-1 w-full leading-loose flex-1">
        {items.map((item) => renderTreeItem(item))}
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default NavigationPanel;
