import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button } from '@frontend/shadcn/components/ui/button';
import {
 Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { TooltipProvider } from '@frontend/shadcn/components/ui/tooltip';
import { Calendar } from '@frontend/shadcn/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@frontend/shadcn/components/ui/popover';
import { CalendarIcon, CheckIcon } from '@revfluence/fresh-icons/regular/esm';
import { format } from 'date-fns';
import { cn } from '@frontend/shadcn/lib/utils';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { Command } from '../command';

const filtersFormSchema = z.object({
  dateType: z.enum([
    'thisWeek',
    'lastWeek',
    'lastMonth',
    'monthToDate',
    'lastQuarter',
    'quarterToDate',
    'last6Months',
    'yearToDate',
    'allTime',
    'custom',
  ]),
  dateRange: z
    .object({
      from: z.date().optional(),
      to: z.date().optional(),
    })
    .refine(
      (data) => {
        if (data.from && data.to) {
          return data.from <= data.to;
        }
        return true;
      },
      {
        message: 'End date must be after start date',
        path: ['to'],
      },
    ),
  network: z
    .array(z.enum(['instagram_posts', 'instagram_reels', 'instagram_stories', 'tiktok', 'pinterest', 'youtube']))
    .min(1, 'At least one network must be selected'),
  projects: z.string().min(1, 'Project selection is required'),
  groups: z.string().min(1, 'Group selection is required'),
  postTypes: z.string().min(1, 'Post type selection is required'),
  dataSource: z.string().min(1, 'Data source selection is required'),
  assignment: z.string().min(1, 'Assignment selection is required'),
});

type FiltersFormData = z.infer<typeof filtersFormSchema>;

type NetworkValue = z.infer<typeof filtersFormSchema.shape.network>[number];

const networkOptions: Array<{ value: NetworkValue; label: string }> = [
  { value: 'instagram_posts', label: 'Instagram Posts' },
  { value: 'instagram_reels', label: 'Instagram Reels' },
  { value: 'instagram_stories', label: 'Instagram Stories' },
  { value: 'tiktok', label: 'TikTok' },
  { value: 'pinterest', label: 'Pinterest' },
  { value: 'youtube', label: 'YouTube' },
];

type FiltersFormProps = {
  onApply: (data: FiltersFormData) => void;
  onCancel: () => void;
  defaultValues?: Partial<FiltersFormData>;
};

export const FiltersForm = ({ onApply, onCancel, defaultValues }: FiltersFormProps) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FiltersFormData>({
    resolver: zodResolver(filtersFormSchema),
    defaultValues: {
      dateType: 'thisWeek',
      dateRange: { from: undefined, to: undefined },
      projects: 'all',
      groups: 'all',
      network: [],
      postTypes: 'all',
      dataSource: 'all',
      assignment: 'all',
      ...defaultValues,
    },
  });

  const dateType = watch('dateType');
  const [fromDateOpen, setFromDateOpen] = useState(false);
  const [toDateOpen, setToDateOpen] = useState(false);
  const [networkPopoverOpen, setNetworkPopoverOpen] = useState(false);

  return (
    <form onSubmit={handleSubmit(onApply)} className="flex flex-col h-full">
      <div className="flex-1 flex flex-col gap-6 py-4 overflow-y-auto">
        <div className="space-y-2">
          <label className="text-sm font-medium">Dates</label>
          <Controller
            name="dateType"
            control={control}
            render={({ field }) => (
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger className={cn(errors.dateType && 'border-red-500')}>
                  <SelectValue placeholder="Select date range" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="thisWeek">This Week</SelectItem>
                  <SelectItem value="lastWeek">Last Week</SelectItem>
                  <SelectItem value="lastMonth">Last Month</SelectItem>
                  <SelectItem value="monthToDate">Month to Date</SelectItem>
                  <SelectItem value="lastQuarter">Last Quarter</SelectItem>
                  <SelectItem value="quarterToDate">Quarter to Date</SelectItem>
                  <SelectItem value="last6Months">Last 6 Months</SelectItem>
                  <SelectItem value="yearToDate">Year to Date</SelectItem>
                  <SelectItem value="allTime">All Time</SelectItem>
                  <SelectItem value="custom">Custom Date</SelectItem>
                </SelectContent>
              </Select>
            )}
          />
          {errors.dateType && <p className="text-sm text-red-500">{errors.dateType.message}</p>}
          {dateType === 'custom' && (
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2 items-center">
                <Controller
                  name="dateRange.from"
                  control={control}
                  render={({ field }) => (
                    <Popover modal open={fromDateOpen} onOpenChange={setFromDateOpen}>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          className={cn(
                            'w-[200px] justify-start text-left font-normal',
                            !field.value && 'text-muted-foreground',
                            errors.dateRange?.from && 'border-red-500',
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {field.value ? format(field.value, 'LLL dd, y') : <span>Start date</span>}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={(date) => {
                            field.onChange(date);
                            setFromDateOpen(false);
                          }}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                  )}
                />
                <span>—</span>
                <Controller
                  name="dateRange.to"
                  control={control}
                  render={({ field }) => (
                    <Popover modal open={toDateOpen} onOpenChange={setToDateOpen}>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          className={cn(
                            'w-[200px] justify-start text-left font-normal',
                            !field.value && 'text-muted-foreground',
                            errors.dateRange?.to && 'border-red-500',
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {field.value ? format(field.value, 'LLL dd, y') : <span>End date</span>}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={(date) => {
                            field.onChange(date);
                            setToDateOpen(false);
                          }}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                  )}
                />
              </div>
              {errors.dateRange?.to && <p className="text-sm text-red-500">{errors.dateRange.to.message}</p>}
            </div>
          )}
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Projects</label>
          <Controller
            name="projects"
            control={control}
            render={({ field }) => (
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger className={cn(errors.projects && 'border-red-500')}>
                  <SelectValue placeholder="Select projects" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All projects</SelectItem>
                </SelectContent>
              </Select>
            )}
          />
          {errors.projects && <p className="text-sm text-red-500">{errors.projects.message}</p>}
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Groups</label>
          <Controller
            name="groups"
            control={control}
            render={({ field }) => (
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger className={cn(errors.groups && 'border-red-500')}>
                  <SelectValue placeholder="Select groups" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All groups</SelectItem>
                </SelectContent>
              </Select>
            )}
          />
          {errors.groups && <p className="text-sm text-red-500">{errors.groups.message}</p>}
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Networks</label>
          <Controller
            name="network"
            control={control}
            render={({ field }) => (
              <Popover open={networkPopoverOpen} onOpenChange={setNetworkPopoverOpen}>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={networkPopoverOpen}
                    className={cn('w-full font-normal justify-between', !!errors.network && 'border-red-500')}
                  >
                    {field.value.length > 0 ? `${field.value.length} selected` : 'Select networks...'}
                    <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[335px] p-0" align="start">
                  <Command label="Search networks">
                    <Command.Input placeholder="Search networks..." />
                    <Command.List>
                      <Command.Empty>No networks found.</Command.Empty>
                      <Command.Group heading="Networks">
                        {networkOptions.map((option) => (
                          <Command.Item
                            key={option.value}
                            onSelect={() => {
                              field.onChange(
                                field.value.includes(option.value)
                                  ? field.value.filter((value) => value !== option.value)
                                  : [...field.value, option.value],
                              );
                            }}
                          >
                            {option.label}
                            <CheckIcon
                              className={cn(
                                'ml-auto h-4 w-4',
                                field.value.includes(option.value) ? 'opacity-100' : 'opacity-0',
                              )}
                            />
                          </Command.Item>
                        ))}
                      </Command.Group>
                    </Command.List>
                  </Command>
                </PopoverContent>
              </Popover>
            )}
          />
          {errors.network && <p className="text-sm text-red-500">{errors.network.message}</p>}
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Post Types</label>
          <Controller
            name="postTypes"
            control={control}
            render={({ field }) => (
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger className={cn(errors.postTypes && 'border-red-500')}>
                  <SelectValue placeholder="Select post types" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Post Types</SelectItem>
                  <SelectItem value="paid">Paid</SelectItem>
                  <SelectItem value="organic">Organic</SelectItem>
                </SelectContent>
              </Select>
            )}
          />
          {errors.postTypes && <p className="text-sm text-red-500">{errors.postTypes.message}</p>}
        </div>

        <div className="space-y-2">
          <div className="flex items-center gap-1">
            <label className="text-sm font-medium">Data Source</label>
            <TooltipProvider>
              <Button variant="ghost" size="icon" className="h-4 w-4 p-0">
                <span className="text-xs">ⓘ</span>
              </Button>
            </TooltipProvider>
          </div>
          <Controller
            name="dataSource"
            control={control}
            render={({ field }) => (
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger className={cn(errors.dataSource && 'border-red-500')}>
                  <SelectValue placeholder="Select data source" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Data Sources</SelectItem>
                  <SelectItem value="authorized">Authorized Data</SelectItem>
                  <SelectItem value="estimated">Estimated Data</SelectItem>
                </SelectContent>
              </Select>
            )}
          />
          {errors.dataSource && <p className="text-sm text-red-500">{errors.dataSource.message}</p>}
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Assignment</label>
          <Controller
            name="assignment"
            control={control}
            render={({ field }) => (
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger className={cn(errors.assignment && 'border-red-500')}>
                  <SelectValue placeholder="Select assignment" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Assignments</SelectItem>
                  <SelectItem value="assigned">Assigned Posts</SelectItem>
                  <SelectItem value="unassigned">Unassigned Posts</SelectItem>
                </SelectContent>
              </Select>
            )}
          />
          {errors.assignment && <p className="text-sm text-red-500">{errors.assignment.message}</p>}
        </div>
      </div>
      <div className="flex flex-row gap-2 justify-start p-4 mx-[-1.5rem] border-t bg-background">
        <Button type="submit" variant="primary" size="sm">
          Apply
        </Button>
        <Button type="button" variant="ghost" size="sm" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </form>
  );
};
