import React, {
  CSSProperties,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState,
  PaginationOptions,
  ColumnPinningState,
  Column,
} from '@tanstack/react-table';

import ReactDragListView from 'react-drag-listview';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@frontend/shadcn/components/ui/table';
import { ChevronDownIcon, ChevronUpIcon } from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@frontend/shadcn/lib/utils';

const getColumnPinningStyles = <T extends {} = {}>(column: Column<T>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px hsl(var(--border)) inset'
      : isFirstRightPinnedColumn
      ? '4px 0 4px -4px hsl(var(--border)) inset'
      : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    zIndex: isPinned ? 3 : 2,
    width: column.getSize(),
  };
};

const getCellPinningStyles = <T extends {} = {}>(column: Column<T>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px hsl(var(--border)) inset'
      : isFirstRightPinnedColumn
      ? '4px 0 4px -4px hsl(var(--border)) inset'
      : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    backgroundColor: isPinned ? 'hsl(var(--primary-foreground))' : undefined,
  };
};

export interface HeaderGroupBorder {
  borderColor?: string;
}

export interface ColumnMetaType {
  headerGroupBorder?: HeaderGroupBorder;
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  sortable?: boolean;
  paginated?: boolean;
  paginationState?: PaginationState;
  paginationOptions?: PaginationOptions;
  wrapperClassName?: string;
  columnPinning?: ColumnPinningState;
  draggable?: boolean;
  selectable?: boolean;
  rowPinning?: boolean;
  bordered?: boolean;
  onColumnDragEnd?: (fromIndex: number, toIndex: number) => void;
  onSelectionChange?: (selectedRows: TData[]) => void;
}

export default function DataTable<TData, TValue>({
  columns: initialColumns,
  data,
  paginated,
  paginationState,
  paginationOptions,
  wrapperClassName,
  sortable = true,
  columnPinning = {},
  draggable = false,
  selectable = false,
  rowPinning = false,
  bordered = false,
  onColumnDragEnd,
  onSelectionChange,
}: DataTableProps<TData, TValue>) {
  const [columns, setColumns] = useState(initialColumns);
  const [rowSelection, setRowSelection] = useState({});

  const handleColumnDragEnd = useCallback(
    (fromIndex: number, toIndex: number) => {
      const newColumns = [...columns];
      const draggedColumn = newColumns.splice(fromIndex, 1)[0];
      newColumns.splice(toIndex, 0, draggedColumn);
      setColumns(newColumns);

      if (onColumnDragEnd) {
        onColumnDragEnd(fromIndex, toIndex);
      }
    },
    [columns, onColumnDragEnd],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: sortable && getSortedRowModel(),
    getPaginationRowModel: paginated && getPaginationRowModel(),
    state: {
      rowSelection,
      columnPinning,
    },
    onRowSelectionChange: setRowSelection,
    ...paginationState,
    ...paginationOptions,
  });
  useEffect(() => {
    setColumns(initialColumns);
  }, [initialColumns]);
  useEffect(() => {
    const selectedRows = table.getSelectedRowModel().rows.map((row) => row.original);
    onSelectionChange?.(selectedRows);
  }, [rowSelection, onSelectionChange, table]);

  const TableContent = () => (
    <Table style={{ minWidth: table.getTotalSize() }}>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {selectable && (
              <TableHead
                className={cn(
                  'px-0 text-left',
                  bordered ? 'border border-gray-200' : '',
                )}
                style={{ width: '40px' }}
              >
                <input
                  type="checkbox"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </TableHead>
            )}
            {headerGroup.headers.map((header) => {
              const isGroupedHeader = header.subHeaders?.length > 0;

              return (
                <TableHead
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: isGroupedHeader ? undefined : header.getSize(),
                    ...getColumnPinningStyles(header.column),
                    position: rowPinning ? 'sticky' : undefined,
                    top: rowPinning ? 0 : undefined,
                    zIndex: 10, // Keep the header above rows
                  }}
                  className={cn(
                    sortable && header.column.getCanSort() ? 'cursor-pointer select-none pr-6' : '',
                    bordered ? 'border border-gray-200' : '',
                  )}
                  onClick={sortable && !isGroupedHeader && header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  {!isGroupedHeader && header.column.getIsSorted() && (
                    <div className="absolute right-2 top-1/2 transform -translate-y-1/2 h-4 w-4">
                      {{
                        asc: <ChevronUpIcon />,
                        desc: <ChevronDownIcon />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>

      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
              {selectable && (
                <TableCell className={cn(bordered ? 'border border-gray-200' : '')}>
                  <input type="checkbox" checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
                </TableCell>
              )}
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  style={getCellPinningStyles(cell.column)}
                  className={cn(bordered ? 'border border-gray-200' : '')}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );

  if (draggable) {
    return (
      <ReactDragListView.DragColumn nodeSelector="th" onDragEnd={handleColumnDragEnd} lineClassName="drag-line">
        <TableContent />
      </ReactDragListView.DragColumn>
    );
  }

  return (
    <div className={wrapperClassName}>
      <TableContent />
    </div>
  );
}
