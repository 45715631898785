import * as React from 'react';
import {
  filter, first, flatten, includes, isEmpty, isNull, isString, lowerCase, map, some, startCase, trim, uniq,
} from 'lodash';
import { format } from 'date-fns';

import { IHeader } from '@affiliates/hooks';
import { GetOfferById_offer } from '@affiliates/queries/types/GetOfferById';
import {
  OFFER_PAYOUT_TYPE, OFFER_PROMO_CODE_STATUS, OFFER_SOURCE, OFFER_STATUS,
} from '@affiliates/types/globalTypes';
import {
  abbreviateAffiliateLink, DataFormat, defaultAffiliateSort, formatValue,
  SHOPIFY_ADMIN_BASE_URL,
} from '@affiliates/utils';
import {
  Col, Row, Typography, Tooltip, Menu, Dropdown, Input, IColumnsType, Tag,
} from '@revfluence/fresh';
import { useEffect } from 'react';
import { Button, message } from 'antd';
import {
  ChartColumnIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SplitIcon,
  TrashCanIcon,
  UpRightFromSquareIcon,
} from '@revfluence/fresh-icons/regular/esm';
import {
  AddMembersButton,
  ExportButton,
  PauseLinksButton,
  RefreshDatesButton,
  ResumeLinksButton,
} from '@affiliates/components/MemberTable/Buttons';
import { EllipsisIcon } from '@revfluence/fresh-icons/solid/esm';
import { InfoCircleFilled, SearchOutlined, UserDeleteOutlined } from '@ant-design/icons';
import _ from 'lodash';
import getDarkHexCodeFromString from '@frontend/app/utils/getDeteministicColor';
import { LINK_FILTERS, PROMO_FILTERS } from '@affiliates/utils';
import { Modal } from '@revfluence/fresh';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/solid';
import { getConversionDetailsOfferBlocks } from '@frontend/app/utils/getConversionDetailsBlocks';
import {
  TOfferMember,
  TMemberTableRow,
  TMemberTableCSVRow,
  OfferMemberStatus,
  MemberStatus,
  TSelectedAffliate,
  IAffiliateLinkMember,
} from './types';
import AffiliateTableSTA from '../AffiliateTableSTA/AffiliateTableSTA';
import {
 getPendingActionFromMembers, isOperationPending, PendingAction, PendingMembers,
} from './PendingMembers';
import { memberIsPending } from './memberIsPending';
import { ToggleCurrencyButton } from './Buttons/ToggleCurrencyButton';
import { TableHeader } from '../TableHeader';
import { ListHeaderCount } from './ListHeaderCount';
import { ManageOfferButton } from './Buttons/ManageOfferButton';
import { FailedNotice } from './FailedNotice';
import { PromoCodeErrorMessage } from './PromoCodeErrorMessage';
import { getTimeStamp } from '../../utils/dateTimeUtils';
import { ZeroState } from './ZeroState';
import { NameCell } from './NameCell';
import { GetOfferAffliatesStatsMultipleShopify_affiliatesStats } from '../../queries/types/GetOfferAffliatesStatsMultipleShopify';
import styles from './AffiliatesMemberTable.scss';
import { getTagType, OFFER_STATUS_TYPE } from '../../utils/getTagType';
import { extractStoreName } from '../../utils/extractShopifyStoreName';
import { useClientFeature } from '../../contexts/ClientFeatureContext';
import { AdditionalDeeplinkTags } from './AdditionalDeeplinksTags';
import { CreateAdditionalDeepLinks } from './Buttons/CreateDeepLinks/createAdditionalDeepLinks';
import { DeeplinkDrawer } from '../DeeplinkDrawer/DeeplinkDrawer';
import { composePromoCodeStatus } from './components/composePromoCodeStatus';
import { composeLinkStatus } from './components/composeLinkStatus';
import { SuccessNotice } from './SuccessNotice';
import { IStatisticsItem } from '../StatisticsBlock/types';
import { calculateStats } from './components/calculateStats';
import DeletedMembersDrawer from '../DeletedMemberDrawer/DeletedMemberDrawer';
import { normalizeName } from '../MembersWizard/components/SelectMembers';

const { useCallback, useMemo, useState } = React;
const { Text, Link } = Typography;

const arrangeTableColumns = (inputArray) => {
  const orderArray: string[] = [
    'affiliateName',
    'externalCode',
    'linkCreationDate',
    'affiliateLinkAbbrev',
    'additionalDeepLinks',
    'affiliateShortLink',
    'linkStatus',
    'payoutId',
    'clicks',
    'conversions',
    'selectedAffliate',
    'sales',
    'avgSale',
    'avgSales',
    'payoutEarned',
    'payoutMade',
  ];
  const orderMap = {};
  orderArray.forEach((field, index) => {
    orderMap[field] = index;
  });
  const sortedArray = inputArray.slice().sort((a, b) => {
    const indexA = orderMap[a.key];
    const indexB = orderMap[b.key];
    if (indexA === undefined && indexB === undefined) {
      return 0;
    } else if (indexA === undefined) {
      return 1;
    } else if (indexB === undefined) {
      return -1;
    } else {
      return indexA - indexB;
    }
  });
  return sortedArray;
};

interface SuccessNoticeState {
  show: boolean;
  count: number;
  action: PendingAction | null;
}
interface IProps {
  buttonActions: {
    addMembers: () => void;
    export: (columns: IHeader[], data: readonly TMemberTableCSVRow[]) => void;
    pause: () => void;
    resume: (refreshDates: boolean) => void;
    openMemberConversionDrawer?: (selectedAffliate: TSelectedAffliate) => void;
    refresh?: () => void;
    storeWiseStats?: () => void;
    storeLevelAffiliateStats?: () => void;
    deleteAffiliatesFromAnOffer?:(selectedMemberIds: number[], offerId: number, offerSource: string, setIsDeletedAction: (isDeletedAction: boolean) => void) => void;
  };
  emailComposeButton: React.ReactElement;
  fixFailedMembers: () => void;
  members: readonly TOfferMember[];
  missingShopifyCredentials: boolean;
  offer: GetOfferById_offer;
  offerSource: OFFER_SOURCE;
  offerStatus: OFFER_STATUS;
  onSelectMembers: (memberIds: number[]) => void;
  selectedAffiliateIds: number[];
  failedPromoCodeErrors: OFFER_PROMO_CODE_STATUS[];
  currencies?: string[];
  showStoreLevelAffiliateStats: boolean;
  connectedStores: { storeName: String; clientShopifyConnectionId: number }[];
  affiliatesStats: GetOfferAffliatesStatsMultipleShopify_affiliatesStats[];
  shopifyStoreName: string;
  onComplete: () => void;
  setHeaderBlock: (value: IStatisticsItem[]) => void;
  isMultipleShopifyOffer: boolean,
  deletedMembers: readonly TOfferMember[];
}
export const MemberTable: React.FC<Readonly<IProps>> = (props) => {
  const {
    buttonActions,
    emailComposeButton,
    fixFailedMembers,
    members,
    missingShopifyCredentials,
    offer,
    offerSource,
    offerStatus,
    onSelectMembers,
    selectedAffiliateIds,
    showStoreLevelAffiliateStats,
    connectedStores,
    affiliatesStats,
    shopifyStoreName,
    onComplete,
    setHeaderBlock,
    isMultipleShopifyOffer,
    deletedMembers,
  } = props;
  const [renderWithCurrency, setRenderWithCurrency] = useState(false);
  const [toggleCurrency, setToggleCurrency] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isDeeplinkDrawerVisibile, setDeeplinkDrawerVisible] = useState(false);
  const [deeplinkData, setDeeplinkData] = useState<TMemberTableRow>(null);
  const { isManageDeepLinksAllowed, deleteCreatorFromOffer, linkForPromo } = useClientFeature();
  const { migrateToGraphQL, enableMultipleShopify: isEnabledMultipleShopify, multiCurrency: isMulticurrency } = useClientFeature();
  const [selectedMember, setSelectedMember] = useState<TMemberTableRow[]>([]);
  const [isDeletedAction, setIsDeletedAction] = useState(false);
  const [isDeletedMembersDrawerVisible, setIsDeletedMembersDrawerVisible] = useState(false);
  const [successNotice, setSuccessNotice] = useState<SuccessNoticeState>({
    show: false,
    count: 0,
    action: null,
  });
  const [hasPendingOperation, setHasPendingOperation] = useState(false);

  const renderShopifyColumn = useCallback(
    (shopifyStoreName, selectedAffliate) => {
      const storeName = extractStoreName(shopifyStoreName);

      const currentPromo = offer.promos[0];
      const isUngrouped = currentPromo.isUngrouped;

      let discountCodeId;
      if (isUngrouped) {
        discountCodeId = extractDiscountCodeId(selectedAffliate?.selectedPromo?.providerMetadata?.ungroupConfig?.discountCodeGid);
      } else {
        discountCodeId = `${extractDiscountCodeId(currentPromo?.discountCodeGId)}/codes`;
        if (selectedAffliate.code) {
          discountCodeId = `${discountCodeId}?query=${selectedAffliate.code}`;
        }
      }
      if (!discountCodeId) return null;

      const url = `${SHOPIFY_ADMIN_BASE_URL}${storeName}/discounts/${discountCodeId}`;
      return (
        <Row justify="start">
          <Tooltip title="View Shopify Page">
            <a href={url} target="_blank" rel="noopener noreferrer">
              <Button
                icon={<UpRightFromSquareIcon color="#167CF4" fontSize={40} />}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            </a>
          </Tooltip>
        </Row>
      );
    },
    [offer],
  );

  const isOfferMigrated = (offerSource === OFFER_SOURCE.SHOPIFY && offer.isNewFlow && offer.promos[0].defaultPayoutId)
    || (offerSource === OFFER_SOURCE.TUNE && offer.links[0].defaultPayoutId);
  const disableButtonForMigration = !migrateToGraphQL ? false : !(migrateToGraphQL && isOfferMigrated);

  const toggleShowCurrencyButton = useCallback(() => {
    setToggleCurrency(!toggleCurrency);
  }, [toggleCurrency]);

  useEffect(() => {
    setRenderWithCurrency(
      isMulticurrency
        && offerSource === OFFER_SOURCE.SHOPIFY
        && props.currencies.length !== 0
        && !includes(props.currencies, 'USD'),
    );
  }, [isMulticurrency, offerSource, props.currencies]);

  const renderNameField = useCallback(
    (data) => (
      <NameCell
        name={data.name}
        imageUrl={data.imageUrl}
        memberId={data.memberId}
        disabled={data.disabled}
        disabledReason={data.disabledReason}
      />
    ),
    [],
  );
  const pendingMembers = useMemo(() => members.filter(memberIsPending), [members]);
  // Track pending operations and show success notice
  useEffect(() => {
    const currentlyPending = isOperationPending(pendingMembers);

    // If an operation starts
    if (currentlyPending && !hasPendingOperation) {
      setHasPendingOperation(true);
      const currentAction = isDeletedAction ? PendingAction.Deleting : getPendingActionFromMembers(pendingMembers);
      setSuccessNotice((prev) => ({
        ...prev,
        count: pendingMembers.length,
        action: currentAction,
        show: false,
      }));
    } else if (!currentlyPending && hasPendingOperation) {
      setHasPendingOperation(false);
      setSuccessNotice((prev) => ({
        ...prev,
        show: true,
      }));
    }
  }, [pendingMembers, hasPendingOperation, isDeletedAction]);

  const handleDismissNotice = useCallback(() => {
    setSuccessNotice((prev) => ({
      ...prev,
      show: false,
    }));
    setIsDeletedAction(false);
  }, []);

  const payouts = useMemo(() => offer?.payouts ?? [], [offer]);
  const renderPayoutVariant = useCallback(
    (payoutId: number | null, returnJsx: boolean = true) => {
      const payout = payouts.find((payout) => payout.id === payoutId);
      if (!payout) return '';
      let text = '';
      switch (payout.payoutType) {
        case OFFER_PAYOUT_TYPE.CLICK:
          text = `${payout.label} ($${payout.flatPayout})`;
          break;
        case OFFER_PAYOUT_TYPE.CONVERSION:
          text = `${payout.label} ($${payout.flatPayout})`;
          break;
        case OFFER_PAYOUT_TYPE.SALE:
          text = `${payout.label} (${payout.percentPayout}%)`;
          break;
        case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
          text = `${payout.label} ($${payout.flatPayout}+${payout.percentPayout}%)`;
          break;
        default:
          break;
      }
      if (!returnJsx) {
        return text;
      }
      return <Text style={{ background: '#F0F0F0', padding: '4px 8px', borderRadius: '8px' }}>{text}</Text>;
    },
    [payouts],
  );
  const pendingMemberCount = pendingMembers.length;
  const toLocale = (date) => {
    if (isNull(date)) {
      return '-';
    }
    const dateDate = new Date(date);
    return dateDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    });
  };
  const PAGE_SIZE = 100;
  const payoutConversionText = useCallback(
    (payoutId: number | string) => renderPayoutVariant(payoutId as number, false) as string,
    [renderPayoutVariant],
  );
  const renderCurrencyColumn = useCallback(
    (data) => {
      if (toggleCurrency || data.currency) {
        return (
          <Row justify="center" align="middle">
            <Col span={24} className={styles.textLeft}>
              {' '}
              <Text>
                $
                {formatValue(DataFormat.Money, data.valueUsd)}
              </Text>
            </Col>
            <Col span={24} className={styles.textLeft}>
              {' '}
              <Text type="secondary">
                {' '}
                {data.currency}
                {' '}
                {formatValue(DataFormat.Money, data.valueBase)}
                {' '}
              </Text>
            </Col>
          </Row>
        );
      } else {
        return `$${formatValue(DataFormat.Money, data)}`;
      }
    },
    [toggleCurrency],
  );
  const renderStatsColumn = useCallback(
    (selectedAffliate: TSelectedAffliate) => {
      if (migrateToGraphQL) {
        return (
          <Row justify="start">
            <Tooltip title="View Stats">
              <Button
                icon={<ChartColumnIcon color="#167CF4" fontSize={40} />}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => buttonActions.openMemberConversionDrawer(selectedAffliate)}
              />
            </Tooltip>
          </Row>
        );
      }
    },
    [buttonActions, migrateToGraphQL],
  );

  function extractDiscountCodeId(externalDiscountCodeGid) {
    if (externalDiscountCodeGid) {
      const idPattern = /\/(\d+)$/;
      const match = externalDiscountCodeGid.match(idPattern);
      return match ? match[1] : null;
    }
  }
  const renderStatusColumn = useCallback((status, linkStatusError) => {
    const displayStatus = isDeletedAction && lowerCase(status) === lowerCase(OFFER_STATUS_TYPE.DEACTIVATING)
      ? OFFER_STATUS_TYPE.DELETING
      : status;
    const statusTag = <Tag color={getTagType(status)}>{startCase(displayStatus)}</Tag>;

    return lowerCase(status) === lowerCase(OFFER_STATUS_TYPE.FAILED_UPDATE) ? (
      <Tooltip title={linkStatusError}>{statusTag}</Tooltip>
    ) : (
      statusTag
    );
  }, [isDeletedAction]);

  const renderColumsWithBase = useCallback(
    (prefix: string, usdValue: number, baseValue?: number, baseCurrency?: string) => {
      if (baseValue && baseCurrency !== 'USD') {
        return (
          <div style={{ display: 'flex', gap: '0px !important', flexDirection: 'column' }}>
            <Text>{`${prefix} ${usdValue}`}</Text>
            <Text style={{ fontSize: '12px', color: '#8C8C8C' }}>{`${baseValue} ${baseCurrency}`}</Text>
          </div>
        );
      }
      return <Text>{`${prefix} ${usdValue}`}</Text>;
    },
    [],
  );
  const isLinkOffer = !isEmpty(offer.links) && !offer.isPromoLink;

  const columns = useMemo(() => {
    const LINK_FILTERS = [
      { text: 'Active', value: 'Active' },
      { text: 'Deactivated', value: 'Deactivated' },
      { text: 'Expired', value: 'Expired' },
      { text: 'Paused', value: 'Paused' },
      { text: 'Others', value: 'Others' },
    ];

    const PROMO_FILTERS = [
      { text: 'Active', value: 'Active' },
      { text: 'Deactivated', value: 'Deactivated' },
      { text: 'Failed', value: 'Failed' },
      { text: 'Expired', value: 'Expired' },
      { text: 'Upcoming', value: 'Upcoming' },
      { text: 'Others', value: 'Others' },
    ];
    const additionalDeeplinkFilters = map(
      uniq(flatten(map(members, (member: IAffiliateLinkMember) => map(member.additionalDeepLinks, 'label')))),
      (label) => ({ text: label, value: label }),
    );

    const columns: IColumnsType<TMemberTableRow> = [
      {
        dataIndex: 'affiliateName',
        key: 'affiliateName',
        title: 'Name',
        render: (data) => (
          <Tooltip title={data.name}>
            {' '}
            {renderNameField(data)}
          </Tooltip>
  ),
        width: 280,
        fixed: 'left',
        sorter: (a, b) => a.affiliateName.name.localeCompare(b.affiliateName.name),
        ellipsis: {
          showTitle: false,
        },
      },
      {
        dataIndex: 'linkCreationDate',
        key: 'linkCreationDate',
        render: (linkCreationDate) => toLocale(linkCreationDate),
        title: 'Link Created',
        width: 170,
        sorter: (a, b) => getTimeStamp(a.linkCreationDate) - getTimeStamp(b.linkCreationDate),
      },
      {
        dataIndex: 'affiliateLinkAbbrev',
        key: 'affiliateLinkAbbrev',
        title: () => (
          <>
            <Row gutter={8}>
              <Col>
                <Text>Primary Link</Text>
              </Col>
              <Col>
                <Tooltip title="Primary Links are unique deep links you assign when adding a member to an offer">
                  <InfoCircleFilled style={{ color: 'grey' }} />
                </Tooltip>
              </Col>
            </Row>
          </>
        ),
        width: 240,
        ellipsis: {
          showTitle: false,
        },
        render: (affiliateLinkAbbrev, record) => (
          <Tooltip title={record.affiliateLink}>
            <Link
              target="_blank"
              rel="noreferrer noopener"
              href={
                isNull(affiliateLinkAbbrev.shortLink)
                  ? record.affiliateLink
                  : affiliateLinkAbbrev.shortAffiliateLinkAbbrev
              }
            >
              {affiliateLinkAbbrev.shortAffiliateLinkAbbrev}
            </Link>
          </Tooltip>
        ),
      },
      {
        dataIndex: 'linkStatus',
        key: 'linkStatus',
        title: 'Status',
        width: 110,
        align: 'left',
        filters: isLinkOffer ? LINK_FILTERS : PROMO_FILTERS,
        onFilter: (value, record) => {
          const predefinedStatuses = isLinkOffer ? LINK_FILTERS.map((f) => f.value) : PROMO_FILTERS.map((f) => f.value);
          return value === 'Others' ? !predefinedStatuses.includes(record.linkStatus) : record.linkStatus === value;
        },
        sorter: (a, b) => a.linkStatus.localeCompare(b.linkStatus),
        render: (status, member) => renderStatusColumn(status, member.linkStatusError),
      },
      {
        dataIndex: 'clicks',
        key: 'clicks',
        render: (data) => formatValue(DataFormat.Integer, data),
        title: 'Clicks',
        width: 100,
        align: 'left',
        sorter: (a, b) => a.clicks - b.clicks,
      },
      {
        dataIndex: 'conversions',
        key: 'conversions',
        render: (data) => formatValue(DataFormat.Integer, data),
        title: 'Conversions',
        width: 130,
        align: 'left',
        sorter: (a, b) => a.conversions - b.conversions,
      },
      {
        dataIndex: 'sales',
        key: 'sales',
        render: (data) => renderCurrencyColumn(data),
        title: 'Sales Amt',
        width: 110,
        align: 'left',
        sorter: (a, b) => Number(a.sales) - Number(b.sales),
      },
      {
        dataIndex: 'avgSale',
        key: 'avgSale',
        // eslint-disable-next-line no-confusing-arrow
        render: (data) => data ? renderCurrencyColumn(data) : '$0.00',
        title: 'Avg. Sale',
        width: 110,
        align: 'left',
        sorter: (a, b) => Number(a.avgSale || 0) - Number(b.avgSale || 0),
      },
      {
        dataIndex: 'payoutEarned',
        key: 'payoutEarned',
        render: (data) => renderCurrencyColumn(data),
        title: 'Commission Earned',
        width: 150,
        align: 'left',
        sorter: (a, b) => Number(a.payoutEarned) - Number(b.payoutEarned),
      },
      {
        dataIndex: 'payoutMade',
        key: 'payoutMade',
        render: (data) => `$${formatValue(DataFormat.Money, data)}`,
        title: 'Payout',
        width: 110,
        align: 'left',
        sorter: (a, b) => Number(a.payoutMade) - Number(b.payoutMade),
      },
      ...(isManageDeepLinksAllowed && isLinkOffer
        ? [
            {
              dataIndex: 'additionalDeepLinks',
              key: 'additionalDeepLinks',
              title: 'Additional Link',
              width: 240,
              ellipsis: {
                showTitle: false,
              },
              filters: additionalDeeplinkFilters,
              filterSearch: true,
              onFilter: (value, record) => {
                const additionalLinks = record.additionalDeepLinks || [];
                return some(map(additionalLinks, 'label'), (label) => label === value);
              },
              render: (_, record) => (
                <AdditionalDeeplinkTags
                  affiliateOfferLinkRecord={record}
                  setDeeplinkData={setDeeplinkData}
                  setDeeplinkDrawerVisible={setDeeplinkDrawerVisible}
                />
              ),
            },
          ]
        : []),
    ];
    if (
      migrateToGraphQL
      && ((offerSource === OFFER_SOURCE.SHOPIFY && offer.promos[0].defaultPayoutId)
        || (offerSource === OFFER_SOURCE.TUNE && offer.links[0].defaultPayoutId))
    ) {
      columns.push({
        dataIndex: 'payoutId',
        key: 'payoutId',
        render: (payoutId) => renderPayoutVariant(payoutId),
        title: 'Payout Type',
        width: 220,
        align: 'left',
        sorter: (a, b) => Number(a.payoutId) - Number(b.payoutId),
      });
    }
    if (migrateToGraphQL && isOfferMigrated) {
      columns.push({
        dataIndex: 'selectedAffliate',
        key: 'selectedAffliate',
        render: (data) => renderStatsColumn(data),
        title: 'Stats',
        width: 100,
        align: 'left',
        sorter: (a, b) => a.selectedAffliate.affiliateOfferId - b.selectedAffliate.affiliateOfferId,
      });
    }
    if (!isEmpty(offer.links) && !offer.isPromoLink) {
      return arrangeTableColumns(columns);
    }

    if (!offer.isNewFlow) {
      const startIndex = 3; // Index where you want to insert the columns
      columns.splice(
        startIndex + 1,
        1, // +1 to insert after the 3rd column
        {
          dataIndex: 'startDate',
          key: 'startDate',
          render: (date) => toLocale(date),
          title: 'Start Date',
          width: 165,
          align: 'left',
          sorter: (a, b) => getTimeStamp(a.startDate) - getTimeStamp(b.startDate),
        },
        {
          dataIndex: 'endDate',
          key: 'endDate',
          render: (date) => toLocale(date),
          title: 'End Date',
          width: 165,
          align: 'left',
          sorter: (a, b) => getTimeStamp(a.endDate) - getTimeStamp(b.endDate),
        },
      );
    }
    if (offer.promos[0].isUngrouped) {
      columns.push(
        {
          dataIndex: 'startDate',
          key: 'startDate',
          render: (date) => toLocale(date),
          title: 'Start Date',
          width: 165,
          align: 'left',
          sorter: (a, b) => getTimeStamp(a.startDate) - getTimeStamp(b.startDate),
        },
        {
          dataIndex: 'endDate',
          key: 'endDate',
          render: (date) => toLocale(date),
          title: 'End Date',
          width: 165,
          align: 'left',
          sorter: (a, b) => getTimeStamp(a.endDate) - getTimeStamp(b.endDate),
        },
      );
    }
    if (offerSource === OFFER_SOURCE.SHOPIFY && showStoreLevelAffiliateStats) {
      for (const store of connectedStores) {
        columns.push({
          title: (
            <div style={{ borderBottom: `2px solid ${getDarkHexCodeFromString(`${store.storeName}storeName`)}` }}>
              {store.storeName}
            </div>
          ),
          key: store.clientShopifyConnectionId,
          width: 550,
          className: 'groupedStore',
          children: [
            {
              title: 'Conversion',
              key: 'affliateDetailedStats',
              dataIndex: 'affliateDetailedStats',
              width: 120,
              render: (data) => {
                const filteredStored = data.find(
                  (d) => d.clientShopifyConnectionId === store.clientShopifyConnectionId,
                );
                return filteredStored?.conversions || 0;
              },
            },
            {
              title: 'Sales Amount',
              key: 'affliateDetailedStats',
              dataIndex: 'affliateDetailedStats',
              width: 120,
              render: (data) => {
                const filteredStore = data.find((d) => d.clientShopifyConnectionId === store.clientShopifyConnectionId);
                return filteredStore?.sales
                  ? renderColumsWithBase('$', filteredStore.sales, filteredStore.salesBase, filteredStore.currency)
                  : 0;
              },
            },
            {
              title: 'Avg. Sales',
              key: 'ccaffliateDetailedStats',
              dataIndex: 'affliateDetailedStats',
              width: 120,
              render: (data) => {
                const filteredStore = data.find((d) => d.clientShopifyConnectionId === store.clientShopifyConnectionId);
                return filteredStore?.avgSale
                  ? renderColumsWithBase('$', filteredStore.avgSale, filteredStore.avgSaleBase, filteredStore.currency)
                  : 0;
              },
            },
            {
              title: 'Commission Earned ',
              key: 'affliateDetailedStats',
              dataIndex: 'affliateDetailedStats',
              width: 160,
              render: (data) => {
                const filteredStore = data.find((d) => d.clientShopifyConnectionId === store.clientShopifyConnectionId);
                return filteredStore?.payoutEarned
                  ? renderColumsWithBase(
                      '$',
                      filteredStore.payoutEarned,
                      filteredStore.payoutEarnedBase,
                      filteredStore.currency,
                    )
                  : 0;
              },
            },
          ],
        });
      }
    }
    columns.push({
      dataIndex: 'shopifyShowData',
      key: 'shopifyShowData',
      render: (shopifyShowData) => renderShopifyColumn(shopifyShowData.shopifyStoreName, shopifyShowData),
      title: 'Shopify',
      width: 100,
      align: 'left',
    });
    if (offer.isPromoLink && linkForPromo) {
      columns.push(
        {
          dataIndex: 'affiliateShortLink',
          key: 'affiliateShortLink',
          title: 'Link',
          width: 240,
          ellipsis: {
            showTitle: true,
          },
          render: (shortLink) => (
            <Link target="_blank" rel="noreferrer noopener" href={shortLink}>
              {shortLink}
            </Link>
          ),
        },
        {
          dataIndex: 'clicks',
          key: 'clicks',
          render: (data) => formatValue(DataFormat.Integer, data || 0),
          title: 'Clicks',
          width: 100,
          align: 'left',
          sorter: (a, b) => a.clicks - b.clicks,
        },
      );
    }
    const finalColumns = [
      columns[0], // affiliate name
      ...(!first(offer.promos)?.isSecureCodes
        ? [
            {
              dataIndex: 'externalCode',
              key: 'externalCode',
              title: 'Promo Code',
              searchable: true,
              width: 190,
              ellipsis: {
                showTitle: true,
              },
              sorter: (a, b) => a.externalCode.localeCompare(b.externalCode),
              render: (code) => (
                <Text copyable ellipsis style={{ maxWidth: '100%', display: 'block' }}>
                  {code}
                </Text>
              ),
            },
          ]
        : []),
      {
        dataIndex: 'createdDate',
        key: 'createdDate',
        title: 'Created Date',
        searchable: true,
        width: 140,
        ellipsis: {
          showTitle: true,
        },
        sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
        render: (createdDate) => (
          <Text ellipsis className="w-full block truncate">
            {format(new Date(createdDate), 'MM/dd/yyyy, H:mm')}
          </Text>
        ),
      },
      columns[3], // status
      ...columns.slice(offer.isNewFlow ? 5 : 4), // remaining columns from conversions onward
    ];

    return arrangeTableColumns(finalColumns);
  }, [
    offer,
    connectedStores,
    isOfferMigrated,
    showStoreLevelAffiliateStats,
    renderColumsWithBase,
    renderCurrencyColumn,
    renderPayoutVariant,
    offerSource,
    renderStatsColumn,
    renderShopifyColumn,
    renderNameField,
    migrateToGraphQL,
    isLinkOffer,
    isManageDeepLinksAllowed,
    members,
    renderStatusColumn,
    linkForPromo,
  ]);
  const data = useMemo(() => {
    const sortedMembers = [...members].sort(defaultAffiliateSort);
    return map(
      sortedMembers,
      (member: TOfferMember): TMemberTableRow => {
        let linkStatus = member.status;
        if (missingShopifyCredentials) {
          linkStatus = OfferMemberStatus.UNKNOWN;
        }
        const commonFields: TMemberTableRow = {
          _sortableFields: {
            affiliateName: trim(member.name).toLowerCase(),
          },
          key: member.memberId.toLocaleString(),
          affiliateName: {
            name: member.name,
            imageUrl: member.imageUrl,
            memberId: member.memberId,
            disabled: member.memberStatus === MemberStatus.DELETED,
            disabledReason:
              member.memberStatus === MemberStatus.DELETED ? 'This member has been deleted in the Members app' : null,
          },
          payoutId: member.payoutId,
          avgSale: member.avgSale,
          conversions: member.conversions,
          email: member.email,
          endDate: null,
          id: String(member.memberId),
          linkStatus,
          payoutEarned: member.payoutEarned,
          payoutMade: member.payoutMade,
          sales: member.sales,
          startDate: null,
          disableSelection: member.memberStatus === MemberStatus.DELETED,
          disabledReason:
            member.memberStatus === MemberStatus.DELETED ? 'This member has been deleted in the Members app' : null,
          name: member.name,
          affiliateOffer: {
            id: member.affiliateOfferId,
            affiliateId: member.affiliateId,
          },
          createdDate: member.createdDate,
          memberId: member.memberId,
        };
        switch (member.source) {
          case OFFER_SOURCE.SHOPIFY:
            return {
              ...commonFields,
              ...(toggleCurrency
                ? {
                    avgSale: {
                      valueBase: member.avgSaleBase,
                      valueUsd: member.avgSale,
                      currency: member.currencies[0],
                    },
                    sales: {
                      valueBase: member.salesBase,
                      valueUsd: member.sales,
                      currency: member.currencies[0],
                    },
                    payoutEarned: {
                      valueBase: member.payoutEarnedBase,
                      valueUsd: member.payoutEarned,
                      currency: member.currencies[0],
                    },
                  }
                : {}),
              externalCode: member.code,
              selectedAffliate: {
                affiliateOfferId: member.affiliateOfferId,
                memberAffiliateOfferId: member.affiliateOfferId,
                name: member.name,
                source: member.source,
                imageUrl: member.imageUrl,
                isMultipleShopifyOffer: !!offer.promos[0]?.connectedClientMetadata?.length,
                status: composePromoCodeStatus(
                  member.status,
                  member.codeStartDate,
                  member.codeEndDate,
                  offer.promos[0],
                  offer.isNewFlow,
                  offer.archivedDate,
                  member.deletedDate,
                ),
                payout: member?.payoutId ? payouts.find((p) => p.id === member.payoutId) : null,
                code: member.code,
                affliateLevelStats: {
                  conversions: member.conversions,
                  sales: member.sales,
                  salesBase: member.salesBase,
                  avgSale: member.avgSale,
                  avgSaleBase: member.avgSaleBase,
                  payoutEarned: member.payoutEarned,
                  payoutEarnedBase: member.payoutEarnedBase,
                  payoutMade: member.payoutMade,
                  payOutDue: member.payoutEarned - member.payoutMade,
                  clicks: null,
                },
                externalDiscountCodeGid: member.externalDiscountCodeGid,
                providerMetadata: member.providerMetadata,
              },
              affliateDetailedStats:
                (showStoreLevelAffiliateStats
                  && affiliatesStats?.find((a) => a.affiliateId === member.affiliateId)?.storeStats)
                || [],
              endDate: member.codeEndDate,
              startDate: member.codeStartDate,
              linkStatus: composePromoCodeStatus(
                member.status,
                member.codeStartDate,
                member.codeEndDate,
                offer.promos[0],
                offer.isNewFlow,
                offer.archivedDate,
                member.deletedDate,
              ),
              linkCreationDate: null,
              shopifyShowData: {
                shopifyStoreName,
                selectedPromo: member,
              },
              ...(offer.isPromoLink && linkForPromo ? {
              clicks: member.clicks,
              affiliateShortLink: member.affiliateShortLink,
            } : {}),
          };
          case OFFER_SOURCE.TUNE:
            return {
              ...commonFields,
              affiliateLink: member.affiliateLink,
              affiliateShortLink: member.affiliateShortLink,
              affiliateLinkAbbrev: {
                shortAffiliateLinkAbbrev: member.affiliateShortLink || abbreviateAffiliateLink(member.affiliateLink),
                shortLink: member.affiliateShortLink,
                defaultDeeplink: member.defaultDeeplink,
              },
              clicks: member.clicks,
              linkStatus: composeLinkStatus(offer, member.status, member.deletedDate),
              linkStatusError: member.linkStatusError,
              linkCreationDate: member.linkCreationDate,
              additionalDeepLinks: member.additionalDeepLinks,
              selectedAffliate: {
                affiliateOfferId: member.affiliateOfferId,
                memberAffiliateOfferId: member.memberAffiliateOfferId,
                name: member.name,
                source: member.source,
                imageUrl: member.imageUrl,
                isMultipleShopifyOffer: !!offer.promos[0]?.connectedClientMetadata?.length,
                status: member.status,
                payout: member?.payoutId ? payouts.find((p) => p.id === member.payoutId) : null,
                uniqueLink: member.affiliateShortLink,
                affliateLevelStats: {
                  conversions: member.conversions,
                  sales: member.sales,
                  avgSale: member.avgSale,
                  payoutEarned: member.payoutEarned,
                  payoutMade: member.payoutMade,
                  payOutDue: member.payoutEarned - member.payoutMade,
                  clicks: member.clicks,
                },
              },
            };
        }
      },
    );
  }, [
    members,
    affiliatesStats,
    showStoreLevelAffiliateStats,
    missingShopifyCredentials,
    toggleCurrency,
    payouts,
    offer,
    shopifyStoreName,
    linkForPromo,
  ]);
  const filteredDataWithSearch = useMemo(() => {
    if (!searchText) {
      return data;
    }
    return _.filter(
      data,
      (item: TMemberTableRow) =>
        _.includes(normalizeName(item.affiliateName.name.toLowerCase()), normalizeName(searchText.toLowerCase()))
      || _.includes(item?.externalCode?.toLowerCase(), searchText.toLowerCase()),
    );
  }, [data, searchText]);

  useEffect(() => {
    setSelectedMember((prevSelected) =>
      prevSelected
        .map((member) => data.find((row) => row.id === member.id) || member)
        .filter(Boolean));
  }, [data]);

  const [filteredData, setFilteredData] = useState(data);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const handleSearch = useCallback(
    (_pagination, _filters, _sorter) => {
      if (!searchText) {
        setFilteredData(filteredDataWithSearch);
      }
      const result = _.filter(
        filteredDataWithSearch,
        (item: TMemberTableRow) =>
          _.includes(normalizeName(item.affiliateName.name.toLowerCase()), normalizeName(searchText.toLowerCase()))
          || _.includes(item?.externalCode?.toLowerCase(), searchText.toLowerCase()),
      );
      setFilteredData(result);
    },
    [searchText, filteredDataWithSearch],
  );

  const handleTableChange = (_pagination, filters, _sorter) => {
    // Set the current filters applied
    let result = [...data]; // Copy the data to avoid mutating the original data
    // Apply status filter
    if (filters.linkStatus && filters.linkStatus.length > 0) {
      const predefinedStatuses = isLinkOffer ? LINK_FILTERS.map((f) => f.value) : PROMO_FILTERS.map((f) => f.value);

      // Filter items by linkStatus, allowing multiple statuses to be selected
      result = result.filter((item) =>
        filters.linkStatus.some((filter) =>
          (filter === 'Others' ? !predefinedStatuses.includes(item.linkStatus) : item.linkStatus === filter)));
    }

    // Apply search filter
    if (searchText) {
      result = _.filter(
        result,
        (item: TMemberTableRow) =>
          _.includes(item.affiliateName.name.toLowerCase(), searchText.toLowerCase())
          || _.includes(item?.externalCode?.toLowerCase(), searchText.toLowerCase()),
      );
    }

    if (_sorter && 'field' in _sorter && _sorter.field) {
      const { field, order } = _sorter;
      result.sort((a, b) => {
        const aValue = _.get(a, field);
        const bValue = _.get(b, field);
        if (aValue < bValue) return order === 'ascend' ? -1 : 1;
        if (aValue > bValue) return order === 'ascend' ? 1 : -1;
        return 0;
      });
    }

    // Check if any filters are applied
    const isFiltered = filters.linkStatus?.length > 0 || !!searchText;
    setFiltersApplied(isFiltered);

    // Update the filtered data
    setFilteredData(result);

    // merge all stats in one object
    const stats = calculateStats(result, props.currencies);
    const headerBlock = getConversionDetailsOfferBlocks(result.length, stats, offerSource, isMulticurrency, isMultipleShopifyOffer, offer.isPromoLink && linkForPromo);
    setHeaderBlock(headerBlock);
  };

  useEffect(() => {
    if (!filtersApplied) {
      setFilteredData(filteredDataWithSearch);
    }
  }, [filteredDataWithSearch, filtersApplied]);

  useEffect(() => {
    handleSearch(null, {}, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const searchBox = () => (
    <Input
      placeholder={`Search members${offerSource === OFFER_SOURCE.SHOPIFY ? ', promo code' : ''}`}
      value={searchText}
      onChange={(e) => {
        setSearchText(e.target.value);
      }}
      prefix={<SearchOutlined />}
      className={styles.customInputBoxLength}
    />
  );
  const rowSelection = {
    onChange: (_, selectedRows: TMemberTableRow[]) => {
      onSelectMembers(selectedRows.map((row) => Number(row.id)));
      setSelectedMember(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.disableSelection, // Column configuration not to be checked
      name: record.name,
      hideSelectAll: true,
    }),
  };
  const selectedMembers = useMemo(() => {
    if (selectedAffiliateIds.length === 0) {
      return data;
    }
    const selectedMemberIdSet = new Set(selectedAffiliateIds);
    return data.filter((row) => selectedMemberIdSet.has(Number(row.id)));
  }, [data, selectedAffiliateIds]);
  // TO-DO = Refactor this to use the CSV export functionality using mapping
  const { csvColumnConfig, csvData } = useMemo(
    () => ({
      csvColumnConfig: columns
        .map((config): IHeader | null => {
          if (!isString(config.title)) {
            return null;
          }
          const field = config.key.toString();
          switch (config.key) {
            case 'affiliateLinkAbbrev':
              return null;
            case 'selectedAffliate':
              return null;
          }
          return {
            headerName: config.title,
            field,
          };
        })
        .concat({
          headerName: 'Email',
          field: 'email',
        })
        .concat({
          headerName: 'Primary Link',
          field: 'affiliateLink',
        })
        .concat({
          headerName: 'Shortened Link',
          field: 'affiliateShortLink',
        })
        .filter((c) => !isNull(c))
        .filter((c) => c.headerName !== 'Shopify'),
      csvData: map(
        selectedMembers,
        (row): TMemberTableCSVRow => {
          const { shopifyShowData, ...rest } = row;
          return ({
            ...rest,
            activeCode: row.externalCode || '',
            affiliateName: row.affiliateName.name,
            email: row.email,
            conversions: row.conversions,
            startDate: format(new Date(row.startDate), 'MM/dd/yyyy, H:mm'),
            endDate: row.endDate ? format(new Date(row.endDate), 'MM/dd/yyyy, H:mm') : '',
            linkCreationDate: format(new Date(row.linkCreationDate), 'MM/dd/yyyy, H:mm'),
            payoutId: payoutConversionText(row.payoutId),
          });
        },
      ),
    }),
    [columns, payoutConversionText, selectedMembers],
  );
  const onClickExport = useCallback(() => {
    buttonActions.export(csvColumnConfig, csvData);
  }, [buttonActions, csvColumnConfig, csvData]);
  const renderCount = () => {
    const tableCount = filteredData.length;
    const selected = selectedAffiliateIds.length > 0;
    const selectedCount = selected ? selectedAffiliateIds.length : tableCount;
    return <ListHeaderCount updatedCount={tableCount} count={selectedCount} selected={selected} />;
  };

  const failedMembers = useMemo(() => filter(members, (m) => m.status === OfferMemberStatus.FAILED), [members]);
  const headerActions = useMemo(() => {
    const isActive = offerStatus === OFFER_STATUS.ACTIVE;
    const disableButtons = !isActive || isEmpty(members) || pendingMemberCount > 0;
    const resumeLinks = () => {
      buttonActions.resume(false);
    };
    const refreshDates = () => {
      buttonActions.resume(true);
    };
    const handleDeleteMembers = () => {
      Modal.confirm({
        title: `Remove ${selectedMember?.length || 0} ${
          selectedMember?.length === 1 ? 'member' : 'members'
        } from this offer?`,
        icon: <TriangleExclamationIcon />,
        content:
          'Please ensure there is no payout due for the selected members you are trying to remove.\n\nNote: Once removed, you will not be able to add these members back to this offer. ',
        okType: 'danger',
        cancelText: 'Cancel',
        okText: 'Remove',
        onOk: async () => {
          setIsDeletedAction(true);
          const selectedMemberIds = selectedMember.map((row) => Number(row.affiliateOffer.id));
          const offerId = offer.promos[0]?.offerId || offer.links[0]?.defaultPayoutId;
          try {
            await buttonActions.deleteAffiliatesFromAnOffer(selectedMemberIds, offerId, offerSource, setIsDeletedAction);
            onComplete();

            const updatedFilteredData = filteredData.filter(
              (item) => !selectedMemberIds.includes(item.affiliateOffer.id),
            );
            const stats = calculateStats(updatedFilteredData, props.currencies);
            const headerBlock = getConversionDetailsOfferBlocks(
              updatedFilteredData.length,
              stats,
              offerSource,
              isMulticurrency,
              isMultipleShopifyOffer,
              offer.isPromoLink && linkForPromo,
            );
            setHeaderBlock(headerBlock);
          } catch (error) {
            setIsDeletedAction(false);
            message.error('Failed to remove members from the offer');
          }
        },
      });
    };
    const menu = (
      <Menu className="">
        {isManageDeepLinksAllowed && offer.links[0] && !offer.isPromoLink && (
          <Menu.Item key="additionalDeeplink">
            <CreateAdditionalDeepLinks
              offer={offer}
              selectedMembers={selectedMembers}
              selectedAffiliateIds={selectedAffiliateIds}
              onComplete={onComplete}
            />
          </Menu.Item>
        )}
        <Menu.Item key="emailCompose">{emailComposeButton}</Menu.Item>
        <Menu.Item key="export">
          <ExportButton disabled={isEmpty(members) || pendingMemberCount > 0} onClick={onClickExport} showText />
        </Menu.Item>
        {deleteCreatorFromOffer && !(offer.isReadOnly) && (
        <Menu.Item key="deletedMembers" onClick={() => setIsDeletedMembersDrawerVisible(true)}>
          <Button type="text">
            <UserDeleteOutlined />
            View Removed Members
          </Button>
        </Menu.Item>
)}
        {!(
          migrateToGraphQL
          && ((offerSource === OFFER_SOURCE.SHOPIFY && offer.promos[0].defaultPayoutId && offer.isNewFlow)
            || (offerSource === OFFER_SOURCE.TUNE && offer.links[0].defaultPayoutId))
        ) && (
          <>
            <Menu.Item key="pauseLinks">
              <PauseLinksButton
                disabled={disableButtons}
                onClick={buttonActions.pause}
                title={isEmpty(offer.links) ? 'Deactivate Promo Codes' : 'Deactivate Links'}
              />
            </Menu.Item>
            <Menu.Item key="resumeLinks">
              <ResumeLinksButton
                disabled={disableButtons || disableButtonForMigration}
                onClick={resumeLinks}
                title={isEmpty(offer.links) ? 'Refresh Promo Codes' : 'Reactivate Links'}
                tooltipText={
                  disableButtonForMigration
                  && `Upgrade your offer to ${offerSource === OFFER_SOURCE.SHOPIFY ? 'refresh codes' : 'activate links'}.`
                }
              />
            </Menu.Item>
            {isEmpty(offer.links) && !offer.isNewFlow && (
              <Menu.Item key="refreshDates">
                <RefreshDatesButton
                  disabled={disableButtons || disableButtonForMigration}
                  onClick={refreshDates}
                  title="Edit Active Dates"
                  tooltipText={disableButtonForMigration && 'Active dates can be edited after upgrading your offer.'}
                />
              </Menu.Item>
            )}
          </>
        )}
      </Menu>
    );
    return (
      <>
        <TableHeader renderCount={renderCount}>
          {isNull(offer.archivedDate) && (
            <AddMembersButton
              disabled={
                selectedAffiliateIds.length > 0 || pendingMemberCount > 0 || !isActive || disableButtonForMigration
              }
              onClick={buttonActions.addMembers}
              tooltipText={disableButtonForMigration && 'Please upgrade your offer to add new members.'}
            />
          )}
          {isNull(offer.archivedDate)
          && migrateToGraphQL
          && ((offerSource === OFFER_SOURCE.SHOPIFY && offer.promos[0].defaultPayoutId)
            || (offerSource === OFFER_SOURCE.TUNE && offer.links[0].defaultPayoutId))
            && (
              <ManageOfferButton
                title="Manage Offer"
                onClick={buttonActions.refresh}
                disabled={disableButtons || !selectedAffiliateIds.length}
              />
            )}
          {renderWithCurrency && !connectedStores.length && (
            <ToggleCurrencyButton
              disabled={disableButtons}
              onClick={toggleShowCurrencyButton}
              title="View Store Data Conversion"
            />
          )}
          {offerSource === OFFER_SOURCE.SHOPIFY
          && isEnabledMultipleShopify
          && !!connectedStores.length
          && (
            <Tooltip title="View Store Data Conversion">
              <Button
                onClick={() => {
                  buttonActions.storeLevelAffiliateStats();
                  onSelectMembers([]);
                }}
                icon={<SplitIcon />}
              />
            </Tooltip>
          )}
          <Dropdown overlay={menu}>
            <Button icon={<EllipsisIcon />} />
          </Dropdown>
          {deleteCreatorFromOffer && (
            <Tooltip
              title={
                offer.isReadOnly
                  ? 'Members cannot be removed from read-only offer. Please manage this on Shopify.'
                  : 'Remove Members'
              }
            >
              <Button
                onClick={() => {
                  handleDeleteMembers();
                }}
                icon={<TrashCanIcon />}
                disabled={disableButtons || !selectedAffiliateIds.length}
              />
            </Tooltip>
          )}
        </TableHeader>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    buttonActions,
    disableButtonForMigration,
    onSelectMembers,
    connectedStores,
    isEnabledMultipleShopify,
    emailComposeButton,
    members,
    offer.links,
    offerStatus,
    onClickExport,
    pendingMemberCount,
    renderCount,
    selectedAffiliateIds,
    renderWithCurrency,
    toggleShowCurrencyButton,
    offerSource,
    migrateToGraphQL,
    offer.promos,
    offer.isNewFlow,
    offer.isReadOnly,
    offer.archivedDate,
    isManageDeepLinksAllowed,
    onComplete,
    selectedMembers,
  ]);
  if (isEmpty(data)) {
    return (
      <ZeroState
        missingShopifyCredentials={missingShopifyCredentials}
        offer={offer}
        offerSource={offerSource}
        offerStatus={offerStatus}
        onClickGenerateLink={buttonActions.addMembers}
        disableAddMemberButton={disableButtonForMigration || !isNull(offer.archivedDate)}
      />
    );
  }
  const pagination = {
    total: filteredData.length,
    pageSize: PAGE_SIZE,
    showSizeChanger: false,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    itemRender: (_current, type, originalElement) => {
      if (type === 'prev') {
        return <Button icon={<ChevronLeftIcon fontSize={24} />} />;
      }
      if (type === 'next') {
        return <Button icon={<ChevronRightIcon fontSize={24} />} />;
      }
      return originalElement;
    },
    showLessItems: true,
    className: 'customPagination',
  };

  const deletedMessage = `Hi, I hope this message finds you well.\n\nAfter careful consideration, we have decided to discontinue your participation in ${offer.name} program. If you had any outstanding commission payments, please rest assured that we have processed those in full.\n\nThank you for your understanding.\n\nBest regards`;

  return (
    <>
      {!missingShopifyCredentials && isNull(offer.archivedDate) && (
        <FailedNotice
          message={<PromoCodeErrorMessage errors={props.failedPromoCodeErrors} />}
          onClickFix={fixFailedMembers}
          visible={failedMembers.length !== 0}
          disabled={!!pendingMemberCount}
        />
      )}
      <PendingMembers pendingMembers={pendingMembers} offerSource={offerSource} isDeletedAction={isDeletedAction} offer={offer} />

      {failedMembers.length === 0 && successNotice.show && successNotice.action && deleteCreatorFromOffer && (
        <SuccessNotice
          onClose={handleDismissNotice}
          offerSource={offerSource}
          successNoticeCount={successNotice.count}
          successNoticeAction={successNotice.action}
          members={selectedMember}
          subject={`Update on Your Participation in ${offer.name} Offer Program`}
          initialMessage={deletedMessage}
          source="success-notice"
          onMessageSent={() => console.log('Email sent')}
          setIsDeletedAction={setIsDeletedAction}
          isDeletedAction={isDeletedAction}
        />
      )}

      <AffiliateTableSTA<TMemberTableRow>
        dataSource={filteredData}
        columns={columns}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={pagination}
        headerActions={headerActions}
        searchBox={searchBox()}
        searchText={searchText}
        sortField="name"
        className="affiliateMemberTable"
        enableEditColumn
        onChange={handleTableChange}
      />
      {isManageDeepLinksAllowed && deeplinkData && (
        <DeeplinkDrawer
          memberData={deeplinkData}
          setDeeplinkDrawerVisible={setDeeplinkDrawerVisible}
          isDeeplinkDrawerVisibile={isDeeplinkDrawerVisibile}
          offer={offer}
          refresh={onComplete}
        />
      )}
      {deleteCreatorFromOffer && (
        <DeletedMembersDrawer
          isVisible={isDeletedMembersDrawerVisible}
          onClose={() => setIsDeletedMembersDrawerVisible(false)}
          deletedMembers={deletedMembers}
          buttonActions={buttonActions}
          columns={columns}
          payoutConversionText={payoutConversionText}
          toggleCurrency={toggleCurrency}
          offer={offer}
          missingShopifyCredentials={missingShopifyCredentials}
          showStoreLevelAffiliateStats={showStoreLevelAffiliateStats}
          shopifyStoreName={shopifyStoreName}
          affiliatesStats={affiliatesStats}
        />
      )}
    </>
  );
};
