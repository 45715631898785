import React, { useCallback, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { GearIcon, ArrowsRotateIcon, SlidersIcon } from '@revfluence/fresh-icons/regular/esm';
import { Button } from '@frontend/shadcn/components/ui/button';
import { Tabs, TabsContent } from '@frontend/shadcn/components/ui/tabs';
import { H3 } from '@frontend/shadcn/components/typography/h3';
import { P } from '@frontend/shadcn/components/typography/p';
import { TooltipProvider } from '@frontend/shadcn/components/ui/tooltip';
import {
 Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger,
} from '@frontend/shadcn/components/ui/sheet';
import {
  Header,
  HeaderLayout,
  HeaderMainContent,
  HeaderH1,
  HeaderActions,
  HeaderTabs,
  HeaderTabsList,
  HeaderTabsTrigger,
} from '../components/header';
import { FiltersForm } from './components/filters-form';
import MembersCard from './components/members-card';
import ReachCard from './components/reach-card';
import ImpressionsCard from './components/impressions-card';
import EngagementCard from './components/engagement-card';

export const SocialAnalytics = () => {
  const { tab } = useParams<{ tab: string }>();
  const history = useHistory();
  const { path } = useRouteMatch();
  const baseUrl = path.replace(':tab', '');
  const [refetching, setRefetching] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const handleRefresh = () => {
    setRefetching(true);
    setTimeout(() => setRefetching(false), 1000);
  };

  const handleTabChange = useCallback(
    (value: string) => {
      history.push(baseUrl + value);
    },
    [baseUrl, history],
  );

  const handleApplyFilters = useCallback(() => {
    setIsFiltersOpen(false);
    // Add filter application logic here
  }, [setIsFiltersOpen]);

  const handleCancelFilters = useCallback(() => {
    setIsFiltersOpen(false);
  }, [setIsFiltersOpen]);

  return (
    <TooltipProvider>
      <Tabs value={tab} onValueChange={handleTabChange}>
        <Header>
          <HeaderLayout>
            <HeaderMainContent>
              <HeaderH1>Social Analytics</HeaderH1>
            </HeaderMainContent>
            <HeaderActions>
              <Button size="headerIcon" variant="outlineHeader">
                <GearIcon />
              </Button>
              <Sheet open={isFiltersOpen} onOpenChange={setIsFiltersOpen}>
                <SheetTrigger asChild>
                  <Button size="headerIcon" variant="outlineHeader">
                    <SlidersIcon />
                  </Button>
                </SheetTrigger>
                <SheetContent>
                  <SheetHeader>
                    <SheetTitle>Filters</SheetTitle>
                  </SheetHeader>
                  <FiltersForm onApply={handleApplyFilters} onCancel={handleCancelFilters} />
                </SheetContent>
              </Sheet>
              <Button size="default" variant="outlineHeader" disabled={refetching} onClick={handleRefresh}>
                <ArrowsRotateIcon className={refetching ? 'mr-2 animate-spin' : 'mr-2'} />
                Refresh
              </Button>
            </HeaderActions>
          </HeaderLayout>
          <HeaderTabs>
            <HeaderTabsList>
              <HeaderTabsTrigger value="summary">Summary</HeaderTabsTrigger>
              <HeaderTabsTrigger value="posts">Posts</HeaderTabsTrigger>
              <HeaderTabsTrigger value="members">Members</HeaderTabsTrigger>
              <HeaderTabsTrigger value="networks">Networks</HeaderTabsTrigger>
            </HeaderTabsList>
          </HeaderTabs>
        </Header>
        <div className="mt-4">
          <TabsContent value="summary">
            <div className="p-4">
              <H3>Analytics Summary</H3>
              <P>
                Get a comprehensive summary of your social media analytics. View key metrics, trends, and insights
                across all platforms and campaigns.
              </P>
            </div>
          </TabsContent>
          <TabsContent value="posts">
            <div className="p-4">
              <H3>Posts Analytics</H3>
              <P>
                Analyze the performance of your social media posts. Track engagement metrics, reach, and impact across
                different content types and platforms.
              </P>
            </div>
            {/* Posts content will go here */}
          </TabsContent>
          <TabsContent value="members">
            <div className="p-4">
              <H3>Members Analytics</H3>
              <P>
                Analyze member performance and engagement metrics. Track individual contributions and identify top
                performers in your social media campaigns.
              </P>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 p-4">
              <MembersCard />
              <ReachCard />
              <ImpressionsCard />
              <EngagementCard />
            </div>
            {/* Members content will go here */}
          </TabsContent>
          <TabsContent value="networks">
            <div className="p-4">
              <H3>Networks Analytics</H3>
              <P>
                Evaluate performance across different social networks. Compare metrics and engagement across platforms
                to optimize your social media presence.
              </P>
            </div>
            {/* Networks content will go here */}
          </TabsContent>
        </div>
      </Tabs>
    </TooltipProvider>
  );
};
