import { useMemo } from 'react';
import { Period } from '../../../gql/social/graphql';

const twoWeeksTimeSpan = 2 * 7 * 24 * 60 * 60 * 1000;

export const getPreviousIntervalRange = (currentIntervalRange: { startDate: string; endDate: string }) => {
  const currentIntervalTimeSpan = new Date(currentIntervalRange.endDate).getTime() - new Date(currentIntervalRange.startDate).getTime();
  return {
    startDate: new Date(new Date(currentIntervalRange.startDate).getTime() - currentIntervalTimeSpan)
      .toISOString()
      .split('T')[0],
    endDate: new Date(new Date(currentIntervalRange.endDate).getTime() - currentIntervalTimeSpan)
      .toISOString()
      .split('T')[0],
  };
};

export const getDefaultIntervalRange = () => ({
  startDate: new Date(Date.now() - twoWeeksTimeSpan).toISOString().split('T')[0],
  endDate: new Date().toISOString().split('T')[0],
  precision: Period.Day,
});

export const useGetPreviousIntervalRange = (currentIntervalRange: { startDate: string; endDate: string }) =>
  useMemo(
    () =>
      getPreviousIntervalRange({ startDate: currentIntervalRange.startDate, endDate: currentIntervalRange.endDate }),
    [currentIntervalRange.endDate, currentIntervalRange.startDate],
  );
