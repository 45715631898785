export function formatNumber(currentIntervalAmount: number | null | undefined) {
  if (!currentIntervalAmount) return 0;

  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(currentIntervalAmount);
}

export function formatNumberDefault(currentIntervalAmount: number | null | undefined) {
  if (!currentIntervalAmount) return 0;

  return new Intl.NumberFormat('en-US').format(currentIntervalAmount);
}
