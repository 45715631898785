import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
  Children,
  isValidElement,
  cloneElement,
  ReactElement,
} from 'react';
import { cn } from '@frontend/shadcn/lib/utils';
import { Search } from 'lucide-react';

/*
  This is a modified version of the Command component from Shadcn.
  It is used to create a command palette for the Social Analytics page.
  It is used to filter the list of items based on the search term.
  It is used to select an item from the list.
  It is used to navigate through the list of items.
*/

interface CommandContextValue {
  selected: number;
  setSelected: (index: number) => void;
  value: string;
  setValue: (value: string) => void;
  filtered: {
    count: number;
  };
  setFiltered: (count: number) => void;
}

const CommandContext = createContext<CommandContextValue | undefined>(undefined);

function useCommand() {
  const context = useContext(CommandContext);
  if (!context) {
    throw new Error('useCommand must be used within a Command provider');
  }
  return context;
}

interface CommandProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  label: string;
  shouldFilter?: boolean;
  filter?: (value: string, search: string) => number;
}

interface CommandInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (value: string) => void;
}

interface CommandListProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

interface CommandEmptyProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

interface CommandGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  heading: string;
}

interface CommandItemProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  onSelect?: () => void;
  disabled?: boolean;
}

interface CommandSeparatorProps extends React.HTMLAttributes<HTMLDivElement> {
  alwaysRender?: boolean;
}

type CommandComponent = React.ForwardRefExoticComponent<CommandProps & React.RefAttributes<HTMLDivElement>> & {
  Input: typeof CommandInput;
  List: typeof CommandList;
  Empty: typeof CommandEmpty;
  Group: typeof CommandGroup;
  Item: typeof CommandItem;
  Separator: typeof CommandSeparator;
};

const defaultFilter = (value: string, search: string) => (value.toLowerCase().includes(search.toLowerCase()) ? 1 : 0);

const CommandRoot = React.forwardRef<HTMLDivElement, CommandProps>(
  ({
 children, label, className, shouldFilter = true, filter = defaultFilter, ...props
}, ref) => {
    const [selected, setSelected] = useState(0);
    const [value, setValue] = useState('');
    const [filtered, setFiltered] = useState({ count: 0 });
    const itemCountRef = React.useRef(0);

    const contextValue = useMemo(
      () => ({
        selected,
        setSelected,
        value,
        setValue,
        filtered,
        setFiltered: (count: number) => setFiltered({ count }),
      }),
      [selected, value, filtered],
    );

    const filteredChildren = shouldFilter
      ? Children.map(children, (child) => {
          if (!isValidElement(child)) return child;

          if (child.type === CommandList) {
            itemCountRef.current = 0;
            const listChildren = Children.toArray(child.props.children)
              .map((listChild) => {
                if (!isValidElement(listChild)) return listChild;

                if (listChild.type === CommandGroup) {
                  const groupChild = listChild as ReactElement<CommandGroupProps>;
                  const items = Children.toArray(groupChild.props.children).filter((item) => {
                    if (!isValidElement(item)) return false;
                    if (item.type !== CommandItem) return true;
                    const itemChild = item as ReactElement<CommandItemProps>;
                    const itemText = itemChild.props.children?.toString() || '';
                    const matches = filter(itemText, value) > 0;
                    if (matches) itemCountRef.current++;
                    return matches;
                  });

                  return items.length === 0 ? null : cloneElement(groupChild, { children: items });
                }

                if (listChild.type === CommandItem) {
                  const itemChild = listChild as ReactElement<CommandItemProps>;
                  const itemText = itemChild.props.children?.toString() || '';
                  const matches = filter(itemText, value) > 0;
                  if (matches) itemCountRef.current++;
                  return matches ? itemChild : null;
                }

                return listChild;
              })
              .filter(Boolean);

            return cloneElement(child as ReactElement<CommandListProps>, { children: listChildren });
          }

          return child;
        })
      : children;

    React.useEffect(() => {
      setFiltered({ count: itemCountRef.current });
    }, [value]);

    return (
      <CommandContext.Provider value={contextValue}>
        <div
          {...props}
          ref={ref}
          role="combobox"
          aria-label={label}
          aria-expanded="true"
          aria-haspopup="listbox"
          aria-controls="command-list"
          className={cn(
            'flex h-full w-full flex-col overflow-hidden rounded-md bg-popover text-popover-foreground',
            className,
          )}
        >
          {filteredChildren}
        </div>
      </CommandContext.Provider>
    );
  },
);

function CommandInput({ className, onValueChange, ...props }: CommandInputProps) {
  const { value, setValue, setSelected } = useCommand();

  return (
    <div className="flex items-center border-b px-3" cmdk-input-wrapper="">
      <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
      <input
        {...props}
        autoComplete="off"
        autoCorrect="off"
        spellCheck={false}
        className={cn(
          'flex h-10 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setSelected(0);
          onValueChange?.(e.target.value);
        }}
      />
    </div>
  );
}

function CommandList({ children, className, ...props }: CommandListProps) {
  const { selected, setSelected } = useCommand();
  const listRef = React.useRef<HTMLDivElement>(null);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const items = listRef.current?.querySelectorAll('[role="option"]') || [];
      if (!items.length) return;

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          const nextIndex = (selected + 1) % items.length;
          setSelected(nextIndex);
          (items[nextIndex] as HTMLElement).scrollIntoView({ block: 'nearest' });
          break;
        case 'ArrowUp':
          e.preventDefault();
          const prevIndex = selected <= 0 ? items.length - 1 : selected - 1;
          setSelected(prevIndex);
          (items[prevIndex] as HTMLElement).scrollIntoView({ block: 'nearest' });
          break;
        case 'Enter':
          e.preventDefault();
          const selectedItem = items[selected] as HTMLElement;
          selectedItem?.click();
          break;
      }
    },
    [selected, setSelected],
  );

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div
      {...props}
      ref={listRef}
      role="listbox"
      id="command-list"
      className={cn('max-h-[300px] overflow-y-auto overflow-x-hidden', className)}
    >
      {children}
    </div>
  );
}

function CommandEmpty({ children, className, ...props }: CommandEmptyProps) {
  const { value, filtered } = useCommand();
  if (!value || filtered.count > 0) return null;

  return (
    <div {...props} role="presentation" className={cn('py-6 text-center text-sm', className)}>
      {children}
    </div>
  );
}

function CommandGroup({
 children, heading, className, ...props
}: CommandGroupProps) {
  return (
    <div
      {...props}
      role="group"
      className={cn(
        'overflow-hidden p-1 text-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground',
        className,
      )}
    >
      <div cmdk-group-heading="" aria-hidden>
        {heading}
      </div>
      <div>{children}</div>
    </div>
  );
}

function CommandItem({
 children, className, onSelect, disabled, ...props
}: CommandItemProps) {
  const { selected, setSelected } = useCommand();
  const ref = React.useRef<HTMLDivElement>(null);
  const [index, setIndex] = useState(-1);

  React.useEffect(() => {
    if (!ref.current) return;

    const parent = ref.current.closest('[role="listbox"]');
    if (!parent) return;

    const items = parent.querySelectorAll('[role="option"]');
    const currentIndex = Array.from(items).indexOf(ref.current);
    if (currentIndex !== index) {
      setIndex(currentIndex);
    }
  }, [children, index]);

  const isSelected = index === selected;

  return (
    <div
      {...props}
      ref={ref}
      role="option"
      aria-selected={isSelected}
      aria-disabled={disabled}
      data-selected={isSelected}
      data-disabled={disabled}
      onClick={disabled ? undefined : onSelect}
      onMouseEnter={() => {
        if (!disabled) {
          setSelected(index);
        }
      }}
      tabIndex={-1}
      className={cn(
        'relative flex cursor-default gap-2 select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none data-[disabled=true]:pointer-events-none data-[selected=true]:bg-accent data-[selected=true]:text-accent-foreground data-[disabled=true]:opacity-50',
        className,
      )}
    >
      {children}
    </div>
  );
}

function CommandSeparator({ className, alwaysRender, ...props }: CommandSeparatorProps) {
  const { value } = useCommand();
  if (!alwaysRender && value) return null;

  return <div {...props} role="separator" className={cn('-mx-1 h-px bg-border', className)} />;
}

const Command = Object.assign(CommandRoot, {
  Input: CommandInput,
  List: CommandList,
  Empty: CommandEmpty,
  Group: CommandGroup,
  Item: CommandItem,
  Separator: CommandSeparator,
}) as CommandComponent;

export { Command, useCommand };
