/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ofzr_157 {
  display: flex;
}

._justify-content-space-between_1ofzr_161 {
  justify-content: space-between;
}

._tabular-nums_1ofzr_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ofzr_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ofzr_157 {
  display: flex;
}

._justify-content-space-between_1ofzr_161 {
  justify-content: space-between;
}

._tabular-nums_1ofzr_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ofzr_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._StartProjectButton_1ofzr_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._StartProjectButton_1ofzr_355 {
  padding-left: 0.9375rem;
}
._StartProjectButton_1ofzr_355[disabled] {
  color: #8f8d91;
}
._StartProjectButton_1ofzr_355 ._icon_1ofzr_371 {
  margin-left: 0.25rem;
}
._StartProjectButton_1ofzr_355:focus {
  border-color: transparent !important;
}

._overlay_1ofzr_378 {
  background-color: #fff;
}

._label_1ofzr_382 {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  height: 2rem;
}
._label_1ofzr_382 ._thumb_1ofzr_389 {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
}

._menu_1ofzr_397 {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 15rem;
  max-height: 23.875rem;
  padding-bottom: 0 !important;
}
._menu_1ofzr_397 .ant-dropdown-menu-submenu-expand-icon {
  top: 50%;
  transform: translateY(-50%);
}
._menu_1ofzr_397 ._createProjectItem_1ofzr_409 {
  position: sticky;
  bottom: 0;
  margin-top: 0.5rem;
  padding: 0.75rem 1rem !important;
  border-top: 0.0625rem solid #ececec;
  background-color: #fdfdfd;
}
._menu_1ofzr_397 ._createProjectItem_1ofzr_409:hover {
  color: #0075ff;
}

._projectTitleItem_1ofzr_421 {
  max-width: 15rem;
  white-space: normal;
}