import React from 'react';
import { Card } from '@frontend/shadcn/components/ui/card';
import { HeartIcon } from '@revfluence/fresh-icons/regular/esm';
import { formatNumber } from '../../../utils/formatNumber';
import {
  HighlightCardHeader,
  HighlightCardTitle,
  HighlightCardContent,
  HighlightCardContentRow,
  HighlightCardContentColumn,
  HighlightCardStatRow,
  HighlightCardStat,
  HighlightCardStatLabel,
} from '../../../components/highlight-cards';

const EngagementCard = () => {
  const organicEngagement = 67890;
  const paidEngagement = 23456;

  return (
    <Card>
      <HighlightCardHeader className="bg-pink-6">
        <HighlightCardTitle>
          <HeartIcon className="mr-2" />
          <span>Engagement</span>
        </HighlightCardTitle>
      </HighlightCardHeader>
      <HighlightCardContent>
        <HighlightCardContentRow>
          <HighlightCardContentColumn className="col-span-3">
            <HighlightCardStatRow>
              <HighlightCardStat className="text-3xl leading-none">{formatNumber(organicEngagement)}</HighlightCardStat>
            </HighlightCardStatRow>
            <HighlightCardStatLabel>Organic</HighlightCardStatLabel>
          </HighlightCardContentColumn>
          <HighlightCardContentColumn className="col-span-2">
            <HighlightCardStatRow>
              <HighlightCardStat className="leading-none">{formatNumber(paidEngagement)}</HighlightCardStat>
            </HighlightCardStatRow>
            <HighlightCardStatLabel>Paid</HighlightCardStatLabel>
          </HighlightCardContentColumn>
        </HighlightCardContentRow>
      </HighlightCardContent>
    </Card>
  );
};

export default EngagementCard;
