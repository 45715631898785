// Vendor imports
import React from 'react';
import {
 Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';
// Aspire imports
// Frontend imports
// Local imports
import { SocialAds } from './Ads/SocialAds';
import { SocialAnalytics } from './Analytics/SocialAnalytics';

const Social = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} render={() => <Redirect to={`${url}/ads`} />} />
      <Route path={`${path}/ads`} component={SocialAds} />
      <Route exact path={`${path}/analytics`} render={() => <Redirect to={`${url}/analytics/summary`} />} />
      <Route path={`${path}/analytics/:tab`} component={SocialAnalytics} />
    </Switch>
  );
};

export default Social;
